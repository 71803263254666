//
// home.scss
//

@mixin home-common {
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
}
.bg-home {
    height: 100vh;    
    @include home-common();
}
.bg-half-260 {
    padding: 260px 0;    
    @include home-common();
}
.bg-half-170 {
    padding: 170px 0;    
    @include home-common();
}
.bg-half {
    padding: 184px 0 90px;
    @include home-common();
}

.bg-auth-home {
    padding: 142px 0;
    @include home-common();
}

//Gradient Home
.gradient{
    background: $primary !important;
    background: linear-gradient(45deg, $primary, $primary) !important;
    opacity: 0.9;
}

.bg-circle-gradiant {
    background: radial-gradient(circle at 50% 50%, rgba($primary, 0.1) 0%, 
                                                   rgba($primary, 0.1) 33.333%,
                                                   rgba($primary, 0.3) 33.333%, 
                                                   rgba($primary, 0.3) 66.666%,
                                                   rgba($primary, 0.5) 66.666%, 
                                                   rgba($primary, 0.5) 99.999%);
}

//Saas
.home-dashboard {
    img {
        position: relative;
        top: 60px;
        z-index: 1;
    }
    
    //Index-onepage.html
    @media (min-width:768px) {
        &.onepage-hero {
            overflow: hidden;
        }
    }
}

.classic-saas-image {
    .bg-saas-shape {
        &:after {
            bottom: 3rem;
            left: 0;
            width: 75rem;
            height: 55rem;
            border-radius: 20rem;
            opacity: 0.9;
            transform: rotate(130deg);
            
            @media (max-width: 767px) {    
                bottom: -5rem;
                left: 10rem;
                width: 30rem;
                height: 30rem;
                border-radius: 10rem;
            } 
        }

        img {
            @media (max-width: 768px) {
                max-width: 550px;
            }
            
            @media (max-width: 767px) {    
                max-width: 100%;
                height: auto;
            }
        }
    }
}

//Studio
.studio-home {
    padding: 7%;
}

//Agency
.agency-wrapper {
    &:before {
        content: "";
        position: absolute;
        bottom: 22rem;
        right: 54rem;
        width: 90rem;
        height: 35rem;
        border-radius: 17rem;
        transform: rotate(45deg);
        background-color: $light;
        z-index: -1;
        
        @media (max-width: 768px) {
            right: 12rem;
        }
        
        @media (max-width: 767px) {
            right: 0;
        }
    }
}

//Marketing
.bg-marketing {
    padding: 76px 0 120px;
    @include home-common();
}

//Coworking Landing
.home-slider {
    .carousel-control-next,
    .carousel-control-prev {
        width: 5%;
    }
    .carousel-item,
    .carousel {
        height: 100vh;
        width: 100%;
    }
    .carousel-item {
        background-position: center center;
        background-size: cover;
        &.active {
            display: flex;
        }
    }
}

//Hotel Landing
.main-slider{
    .slides {
        .bg-slider {
            background-size: cover;
            height: 100vh;
            position: relative;
            @include home-common(); 
            &.shopping {
                height: 75vh;
            }
        }
    }
}        
.flex-control-paging li a{
    background: $white;
    box-shadow: none;
    &.flex-active {
        background: $primary;
        cursor: default;
        font-size: 17px;
        width: 15px;
        height: 15px;
        box-shadow: none;
    }
}
.flex-control-nav {
    display: none;
}
.bg-animation-left {
    &:after {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        width: 70%;
        height: 100%;
        background: rgba($white, 0.85);
        clip-path: polygon(0 0, 90% 0, 70% 100%, 0% 100%);
        
        @media (max-width: 767px) {
            width: 100%;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
    }
    &.dark-left {
        &:after {
            background: rgba($dark, 0.6);
        }
    }
    &.crypto-home {
        &:after {
            background: $primary;
            width:80%;
            clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
        }
    }
    &.task-management-home {
        &:after {
            background: url("../images/task/bg.png") $primary;
            width:100%;
            clip-path: circle(65% at 40% 25%);
        }
    }
}

//page-invoice.html
.bg-invoice {
    padding: 100px 0;
    @include home-common();
}

//Application
.classic-app-image {
    .bg-app-shape {
        &:after {
            bottom: 3rem;
            left: -8rem;
            width: 55rem;
            height: 35rem;
            border-radius: 20rem;
            transform: rotate(120deg);
            opacity: 0.9;
        }
    }
    .app-images {
        position: absolute;
        bottom: 60px;
        right: -20px;
    }
}

.classic-app-image .bg-app-shape, 
.classic-saas-image .bg-saas-shape {
    &:after {
        content: " ";
        position: absolute;
        z-index: -1;
    }
}
.classic-app-image .bg-app-shape, 
.classic-saas-image .bg-saas-shape {
    &:after {
        box-shadow: 0 0 40px rgba($primary, 0.5);
        background-color: $primary;
    }
}

//Landing one
.shape-before {
    .shape-img {
        position: absolute;
        top: -50px;
        left: -35px;
        z-index: -1;
        height: auto;
        overflow: hidden;
    }
}

//corporate business
.swiper-slider-hero {
    .swiper-container,
    .swiper-slide .slide-inner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .swiper-container {        
        .swiper-button-prev,
        .swiper-button-next {
            background: transparent;
            width: 35px;
            height: 35px;
            line-height: 35px;
            margin-top: -30px;
            &:before {
                font-family: "Material Design Icons";
                font-weight: 900;
                color: $white;
            }
            &:hover {
                background: $primary;
                border-color: $primary !important;
            }
        }
        .swiper-button-prev {
            left: 10px;
            &:before {
                content: "\f141";
            }
        }
        .swiper-button-next {
            right: 10px;
            &:before {
                content: "\f0142";
            }
        }
    }
    .swiper-pagination-bullet {
        color: $gray-100;
        background: transparent;
    }
    .swiper-pagination-bullet-active {
        color: $white;
    }
    .swiper-container-horizontal 
    > .swiper-pagination-bullets,
    .swiper-pagination-custom, 
    .swiper-pagination-fraction {
        bottom: 45px;
    } 
    .swiper-container-horizontal 
    > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 13px;
    }
}

// Responsive 
@media (min-width: 769px){    
    //studio 
    .bg-studio {
        &:after{
            content: "";
            position: absolute;
            right: 0px;
            top: 0px;
            width: 50%;
            height: 100%;
            background: $white none repeat scroll 0 0;
            z-index: 2;
        }
    }
}

@media (max-width: 768px) {
    .bg-half {
        padding: 169px 0 50px;
    }
}

@media (max-width: 767px) {
    .bg-home, .bg-half-170, .bg-half-260, .main-slider .slides .bg-slider, .bg-marketing, .swiper-slider-hero .swiper-container .swiper-slide {        
        padding: 150px 0;
        height: auto;
    } 
}


/*============== erp_banner_area_two css cloud-responsive =============*/


@media (min-width:2000px) {
    .erp_banner_area_two .animation_img {
        margin-top: -320px;
    }
}

@media(max-width:1650px) {
    .section_container {
        height: 795px;
        margin-bottom: 40px;
    }
}

@media (max-width:1450px) {
    .erp_banner_area_two .animation_img {
        margin-top: -279px;
    }
}

@media (max-width:1350px) {
    .erp_banner_area_two .animation_img img {
        max-width: 100%;
    }
}

@media (max-width:991px) {
    .section_container {
        height: 50%;
    }
}

@media (max-width:768px) {
    .erp_banner_area_two {
        font-size: 22px;
    }

    .erp_banner_area_two .section_container {
        margin-left: 0;
        margin-right: 0;
    }

    .section_container .intro_content {
        position: relative;
        padding-top: 150px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .section_container h1, .erp_banner_area_two .section_container h1 {
        font-size: 44px;
    }
}

@media (max-width:650px) {
    .erp_banner_area_two .section_container h1 {
        font-size: 22px;
        line-height: 38px;
    }

    .erp_banner_area_two .section_container {
        padding-top: 100px;
    }

    .erp_banner_area_two .animation_img {
        margin-top: -322px;
    }

    .erp_banner_area_two {
        top: calc(100% - 86px);
    }
}

@media (max-width:576px) {
    .section_container h1 {
        font-size: 30px;
        line-height: 45px;
    }

    .section_container {
        display: block;
    }

    .section_container {
        width: 100%;
    }

    .section_container {
        position: relative;
        margin-top: 10px;
        display: block;
        max-width: 100%;
        width: 100%;
    }
}

/*============== erp_banner_area_two css cloud-responsive=============*/
/*============== erp_banner_area_two css cloud-icon =============*/
.erp_banner_area_two {
    background: #f9f9fe;
    position: relative;
    z-index: 0;
    max-width: 100%;
    width: 100%;
}

.erp_banner_area_two + section {
    position: relative;
    z-index: 1;
}

.erp_banner_area_two .animation_img {
    margin-top: -380px;
}

.erp_banner_area_two .cloud_animation {
    margin-bottom: 0;
}

.erp_banner_area_two .cloud_animation li {
    position: absolute;
}

.erp_banner_area_two .cloud_animation li:nth-child(1) {
    top: 120px;
    left: 170px;
    -webkit-animation: jurk 4s 5s linear infinite alternate;
    animation: jurk 4s 5s linear infinite alternate;
}

.erp_banner_area_two .cloud_animation li:nth-child(2) {
    top: 320px;
    left: 370px;
    -webkit-animation: jurk 4s 2s linear infinite alternate;
    animation: jurk 4s 2s linear infinite alternate;
}

.erp_banner_area_two .cloud_animation li:nth-child(3) {
    left: 120px;
    top: 50%;
    -webkit-animation: jurk 3s 3s linear infinite alternate;
    animation: jurk 3s 3s linear infinite alternate;
}

.erp_banner_area_two .cloud_animation li:nth-child(6) {
    top: 120px;
    right: 170px;
    -webkit-animation: jurk 5s 3s linear infinite alternate;
    animation: jurk 5s 3s linear infinite alternate;
}

.erp_banner_area_two .cloud_animation li:nth-child(4) {
    top: 400px;
    right: 280px;
    -webkit-animation: jurk 4s 2s linear infinite alternate;
    animation: jurk 4s 2s linear infinite alternate;
}

.erp_banner_area_two .cloud_animation li:nth-child(5) {
    right: 120px;
    top: 280px;
    -webkit-animation: jurk 8s 2s linear infinite alternate;
    animation: jurk 8s 2s linear infinite alternate;
}

.erp_banner_area_two .erp_shap {
    content: '';
    display: block;
    position: absolute;
    top: calc(100% - 190px);
    background-color: white;
    min-height: 390px;
    width: 2000px;
    left: -77px;
    -webkit-transform: rotate(-6deg);
    -webkit-transform-origin: 0 0 0;
    -moz-transform: rotate(-6deg);
    -moz-transform-origin: 0 0 0;
    -o-transform: rotate(-6deg);
    -o-transform-origin: 0 0 0;
    -ms-transform: rotate(-6deg);
    -ms-transform-origin: 0 0 0;
    transform: rotate(-6deg);
    z-index: -1;
}

.erp_banner_area_two .erp_shap_two {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
}

.erp_banner_area_two .animation_img img {
    -webkit-box-shadow: 0px 40px 100px 0px rgba(1, 1, 64, 0.08);
    box-shadow: 0px 40px 100px 0px rgba(1, 1, 64, 0.08);
}

.erp_banner_area_two .section_container .intro_content h1 {
    font-size: 50px;
    line-height: 64px;
    color: #2c2c51;
    font-weight: 600;
}

.erp_banner_area_two .section_container .intro_content p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 40px;
}

.erp_banner_area_two .section_container .intro_content .er_btn {
    padding: 10px 37px;
    margin-top: 0;
}

@-webkit-keyframes cloud {
    0% {
        left: 14%;
        top: 200px;
        opacity: 0;
    }

    20% {
        left: 44%;
        top: 100px;
        opacity: 1;
    }

    40% {
        left: 65%;
        top: 50px;
        opacity: 1;
    }

    60% {
        left: 80%;
        top: 130px;
        opacity: 1;
    }

    80% {
        left: 90%;
        top: 180px;
        opacity: .5;
    }

    100% {
        left: 100%;
        top: 230px;
        opacity: 0;
    }
}

@keyframes cloud {
    0% {
        left: 14%;
        top: 200px;
        opacity: 0;
    }

    20% {
        left: 44%;
        top: 100px;
        opacity: 1;
    }

    40% {
        left: 65%;
        top: 50px;
        opacity: 1;
    }

    60% {
        left: 80%;
        top: 130px;
        opacity: 1;
    }

    80% {
        left: 90%;
        top: 180px;
        opacity: .5;
    }

    100% {
        left: 100%;
        top: 230px;
        opacity: 0;
    }
}

@-webkit-keyframes animateCloud {
    0% {
        left: -20%;
        opacity: 1;
    }

    100% {
        left: 100%;
        opacity: 1;
    }
}

@keyframes animateCloud {
    0% {
        left: -20%;
        opacity: 1;
    }

    100% {
        left: 100%;
        opacity: 1;
    }
}

@-webkit-keyframes jurk {
    0% {
        -webkit-transform: translate3d(-10px, -10px, 0);
        transform: translate3d(-10px, -10px, 0);
        opacity: 1;
    }

    50% {
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(30px, 0px, 0);
        transform: translate3d(30px, 0px, 0);
        opacity: 1;
    }
}

@keyframes jurk {
    0% {
        -webkit-transform: translate3d(-10px, -10px, 0);
        transform: translate3d(-10px, -10px, 0);
        opacity: 1;
    }

    50% {
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(30px, 0px, 0);
        transform: translate3d(30px, 0px, 0);
        opacity: 1;
    }
}

/*============== erp_banner_area_two css =============*/
