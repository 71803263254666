@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./assets/scss2/custom.scss";

// //Import Light mode
@import "./assets/scss2/style.scss";

.packages-items {
    & .packages-main {
        display: flex;
        flex-wrap: wrap;
        overflow-x: auto;
    }

    & .packages-wrapper {
        display: table;
        flex-wrap: wrap;
        overflow-x: auto;
        margin-right: 7px;
    }

    & .feature-wrapper {
        display: table;
        flex-wrap: wrap;
        overflow-x: auto;
        padding-left: 9px;
        padding-right: 9px;
        padding-top: 6px;
        border-radius: 9px;
        background-color: #e2e7fd;

        & .feature-header {
            color: black;
            font-size: 1rem;
            font-weight: 600;
        }

        & .feature-group-header {
            font-style: normal;
            font-weight: 600;
            text-transform: uppercase;
            color: #7a90fa;
            // border-bottom: 1px solid #646464;
            margin-bottom: 1%;
            margin-top: 1%;
        }
    }

    & .packages-item {
        position: relative;
        border-radius: 8px;
        border: solid 1px #e5e5e5;
        background-color: #fcfcfc;
        padding: 0.4rem 0.6rem 0.6rem 1rem;
        margin-bottom: 0.5rem;
        cursor: pointer;

        &.condensed {
            padding: 0.25rem 0.4rem 0.25rem 0.7rem;
            min-width: 175px;
        }

        &.use-opacity {
            opacity: 0.6;
        }

        &.use-opacity:hover {
            opacity: 1;
        }

        & h5 {
            line-height: 1.25;
            letter-spacing: -0.032rem;
            display: -moz-box;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-stretch: normal;
            font-style: normal;
            color: gray;
            margin: 0;
        }

        & p {
            font-size: 0.9em;
            display: -webkit-box;
            line-height: 1.8rem;
            color: #646464;
            letter-spacing: -0.024rem;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            margin-bottom: 0;
        }

        &:hover .top-right-button {
            display: inherit;
        }

        &.selected {
            border: 3px solid #a1b1fb;
            -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);

            & h5 {
                color: black;
            }

            &.use-opacity {
                opacity: 1;
            }

            &:after {
                content: '';
                position: absolute;
                right: -11px;
                top: 1rem;
                width: 0;
                height: 0;
                border-bottom: 10px solid transparent;
                border-left: 10px solid #a1b1fb;
                border-top: 10px solid transparent;
                clear: both;
            }
        }

        &.selectedinvoice {
            border: 3px solid #7ad0fa;
            -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);

            & h5 {
                color: black;
            }
        }

        &.condensed .h3 {
            font-size: 1.35rem !important;
        }

        &.condensed .h5 {
            font-size: small !important;
        }

        &.condensed .h6 {
            font-size: smaller !important;
        }

        & .packages-detail {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            height: 100%;

            & .city-detail {
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 150px;
                white-space: nowrap;
                line-height: 1.8rem;
                color: #646464;
                letter-spacing: -0.024rem;
                font-size: 0.85rem;
            }
        }

        & .top-right-button {
            position: absolute;
            top: 10px;
            right: 12px;
            display: none;
        }
    }

    & .feature-item {
        font-size: 0.85rem;
        position: relative;
        border-radius: 6px;
        border: solid 1px #c9d2fd;
        background-color: white;
        padding: 0.2rem 0.4rem 0.4rem 0.7rem;
        margin-bottom: 2%;
        // opacity: 0.8;

        & .feature-item-description {
            color: gray;
            font-size: 0.9em;
        }

        & table {
            .feature-line {
                cursor: pointer;
            }
        }

        & h5 {
            line-height: 1.5;
            letter-spacing: -0.032rem;
            display: -moz-box;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-stretch: normal;
            font-style: normal;
            color: gray;
            margin: 0;
        }

        & p {
            font-size: 0.9em;
            display: -webkit-box;
            line-height: 1.8rem;
            color: #646464;
            letter-spacing: -0.024rem;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            margin-bottom: 0;
        }

        &:hover .top-right-button {
            display: inherit;
        }

        &.selected {
            border: 3px solid #7a90fa;
            -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
            opacity: 1;

            & h5 {
                color: black;
            }
        }

        &.selectedinvoice {
            border: 3px solid #7ad0fa;
            -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);

            & h5 {
                color: black;
            }
        }

        & .feature-detail {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            height: 100%;

            & .city-detail {
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 150px;
                white-space: nowrap;
                line-height: 1.8rem;
                color: #646464;
                letter-spacing: -0.024rem;
                font-size: 0.85rem;
            }
        }

        & .top-right-button {
            position: absolute;
            top: 10px;
            right: 12px;
            display: none;
        }
    }

    .load-more {
        color: gray;
        font-size: 0.9em;
        cursor: pointer;
        margin-top: -1em;
        padding-top: 1em;
        &:hover {
            color: orange;
        }
    }
}

.swal2-title {
    font-size:2em;
}
.swal2-popup.swal2-modal {
	font-size:1em !important;
}
.swal2-popup.swal2-toast .swal2-title {
    font-size: 1.25em !important;
}

.react-bootstrap-table td.expired {
    text-decoration: line-through;
}
.react-bootstrap-table td.trial {
    color: gray;
}

div.pricing-rates {
    & li.pricing-feature {
        font-size: 0.9em;
    }
}

//Import Dark RTL mode
// @import './assets/scss2/style-dark-rtl.scss';

//Import RTL mode
// @import './assets/scss2/style-rtl.scss';

// Import Dark Mode
// @import './assets/scss2/style-dark.scss';