/* Template Name: Landrick - Saas & Software Landing Page Template
   Author: Shreethemes
   E-mail: shreethemes@gmail.com
   Created: August 2019
   File Description: Main CSS file of the template live preview
*/
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

/*@import url(https://unpkg.com/@webpixels/css/dist/index.css);
*/
.bg-primary {
    background-color: #2f55d4 !important;
}

.classForContainer svg {
    height: 25px;
    width: 25px;
    vertical-align: middle;
    overflow: hidden;
    margin-top: 11px;
    margin-left: 11px;
    fill: none;
    stroke: #007bff !important;
}

.erp_banner_area_two {
    background-color: white !important;
}

.text-primary {
    color: #2f55d4 !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #284bc1 !important;
}


.bg-secondary {
    background-color: #5a6d90 !important;
}

.text-secondary {
    color: #5a6d90 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #506180 !important;
}

.bg-success {
    background-color: #2eca8b !important;
}
.password-form{
    text-align: left;
    color: #333;
    font-size: 14px;
}

.password-form .password-form-col p{
  font-weight: 600;
}
.password-form .password-form-col ul{
    list-style: none;
    line-height: 20px;
    margin-top: 8px;

}
.password-form .password-form-col ul li::before{
    content: "\2022";
    color: #0044ff;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: 0em;
    font-size: 14px;
}


.text-success {
    color: #2eca8b !important;
}

.btn:hover {
    color: #f77e66;
}

a.text-success:hover,
a.text-success:focus {
    color: #29b57d !important;
}

.bg-warning {
    background-color: #f17425 !important;
}

.text-warning {
    color: #f17425 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #ed650f !important;
}

.bg-info {
    background-color: #17a2b8 !important;
}

.text-info {
    color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #148ea1 !important;
}

.bg-danger {
    background-color: #e43f52 !important;
}

.text-danger {
    color: #e43f52 !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #e1293e !important;
}

.bg-dark {
    background-color: #3c4858 !important;
}

.text-dark {
    color: #3c4858 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #323c49 !important;
}

.bg-muted {
    background-color: #8492a6 !important;
}

.text-muted {
    color: #8492a6 !important;
}

a.text-muted:hover,
a.text-muted:focus {
    color: #75859b !important;
}

.bg-light {
    background-color: #f8f9fc !important;
}

.text-light {
    color: #f8f9fc !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #e6eaf4 !important;
}

.bg-footer {
    background-color: #202942 !important;
}

.text-footer {
    color: #202942 !important;
}

a.text-footer:hover,
a.text-footer:focus {
    color: #181e30 !important;
}

.footfont {
    font-size: 14px;
}

.list-inline-item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 5px;
}

.bg-blue {
    background: #2f55d4;
}

.bg-red {
    background: #ff5b69;
}

.bg-slateblue {
    background: #766df4;
}

.bg-purple {
    background: #7952B3;
}

.bg-cyan {
    background: #00c9a7;
}

.bg-green {
    background: #6dc77a;
}

.bg-skobleoff {
    background: #0f7173;
}

.bg-skyblue {
    background: #32c2ec;
}

.bg-yellow {
    background: #f7961c;
}
.rounded-top {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
}

.rounded-left {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}

.rounded-bottom {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}
.rounded-right {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}
.small,
small {
    font-size: 100%;
}

.card .card-body {
    padding: 1.5rem;
}
body {
    font-family: "Nunito", sans-serif;
    overflow-x: hidden !important;
    font-size: 16px;
    color: #161c2d;
    margin: 0;
    padding: 0;
}
.bg-slider {
    min-height: 500px;
}
@media (min-width: 320px) and (max-width: 440px) {
    .reg-img{
        display: none;
    }
    .reg-bil{
        justify-content: center;
        display: flex;
        border: none;
        width: 100%;
        top: -8%;
        height: 100%;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    .footer{
        margin-top: -50%;
    }
    h1 {
        font-size: 30px !important;
    }

    h2 {
        font-size: 27px !important;
        color: #223d7f;
    }

    h3 {
        font-size: 24px !important;
    }

    h4{
        font-size: 20px !important;
    }

    h5{
        font-size: 17px !important;
    }

    .logo {
        margin-left: 3%;
        margin-top: 2%;
        letter-spacing: 1px;
        width: 120px;
    }

    .btn-login {
        margin-top: 0px;
    }

    .menu-extras {
        margin-right: 0px;
    }

    .btn-icon{
        margin-left: -50%;
    }

    .buy-button{
        margin-left: -23%;
    }

    .slider-top {
        margin-top: -103%;
        width: 112%;
        margin-left: -6%;
    }

    .slick-slide {
        background-size: 200% !important;
        background-position: right 10% bottom 20%;
        background-repeat: no-repeat;
        margin: 0px;
        padding: 0px;
        margin-top: 180px;

    }

    .slider-btn {
        position: relative;
        left: -28px;
    }

    .depo-slider-btn {
        margin-left: -9%;
    }

    .slider-desc {
        max-width: 355px;
    }

    .btn-detayli {
        position: relative;
        margin-left: 0px;
    }

    .container-neden {
        margin-top: -1rem;
    }

    .ndnblt12 {
        margin-left: 20px;
    }

    .ndnblt-icon {
        width: 70px;
        height: 70px;
    }

    .main-slider .slides .bg-slider {
        height: 90vh;
        min-height: 600px;
    }

    .main-slider-i .slides-i .bg-slider-i {
        height: 110vh;
    }

    .verimerkezi {
        text-align: center;
        color: #223d7f;
    }

    .pveri {
        text-align: center;
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: #596369;
    }

    .bulut-nlr {
        border: none;
        width: 117%;
        margin-left: -11%;
    }

    .display-4 {
        font-size: 35px !important;
        text-align: justify;
        max-width: 100%;
    }

    .title-heading .para-desc {
        font-size: 15px !important;
        line-height: normal;
        max-width: 350px;
        color: #223d7f;
    }

    .btn-ed {
        padding: 5px 15px;
    }

    .btnstil {
        display: block !important;
        font-size: 15px;
        width: 225px;
        margin-left: 9%;
    }

    .btnstildetay {
        display: block !important;
        width: 55%;
        margin-left: -4%;
        font-weight: bold;
    }

    .btnstilnum{
        display: block !important;
        width: 66%;
        margin-left: 7%;
        margin-top: -7%;
        font-weight: bold;
    }

    .btn-stildetay{
        margin-left: 10%;
    }

    .btn-detayli {
        left: 40px;
    }

    .btn-store {
        display: none;
    }

    .h3test {
        margin-top: 2%;
        color: #223d7f;
        text-align: center;
    }

    .h3test-nlr {
        margin-top: 2%;
        margin-left: -2%;
        color: #223d7f;
        font-weight: 600;
        font-size: 30px;
    }

    .prs-h3test {
        margin-top: 2%;
        max-width: 303px;
        color: #223d7f;
    }

    .yada {
        display: none;
    }

    .h4test {
        text-align: center;
        margin-top: 12px;
        margin-left: -1%;
    }

    .ekran {
        width: 108%;
        margin-left: -4%;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .tekyerden {
        margin-left: -17px;
        margin-right: -49px;
        margin-top: -17px;
    }

    .tekyerden-iki {
        margin-left: -15px;
        margin-right: -15px;
        border: none;
    }

    .eticaretfull {
        background-color: #e4f2f9;
        width: 98%;
        margin-left: 1%;
        border: none;
    }

    .text-l {
        text-align: left;
    }

    .dahaneler {
        position: relative;
        width: 84%;
        height: auto;
    }

    .dhimg {
        position: relative;
        margin-top: 6%;
        width: 119%;
        height: auto;
        margin-left: -8px;

    }

    .dn-iki {
        width: 137%;
        margin-left: -49px;
    }

    .deposayim {
        width: 98%;
        margin-left: 1%;
    }

    .dhimg-laptop {
        width: 78%;
        margin-left: -16px;
    }

    .dhimgpos {
        width: 130%;
        margin-left: -15%;
        margin-bottom: -1%;
    }

    .responsiveapp {
        width: 115%;
        margin-left: -8%;
        margin-bottom: 10%;
        margin-top: 5%;
    }

    .perabutt{
        margin-bottom: 10%;
    }

    .dhimglogo {
        width: 135%;
        margin-left: -10%;
        margin-bottom: 5%;
        margin-top: 10%;
    }

    .dhimgbur {
        position: relative;
        margin-top: 6%;
        width: 140%;
        height: auto;
        margin-left: -10%;
    }

    .mor-btn {
        margin-left: 19%;
    }

    .play {
        margin-left: 24%;
        margin-bottom: 45px;
        margin-top: 15px;
    }

    .onmuha-link {
        padding-right: 8px;
        padding-left: 8px;
    }

    .btn-footer-nav {
        font-size: 14px;
    }

    .sss-img {
        width: 100%;
    }

    .modul-btn- {
        display: grid;
        margin-bottom: -15px;
    }

    .modulbtn-ara {
        margin-bottom: -10%;
    }

    .pera-h1 {
        max-width: 104%;
    }

    .pera-h3test {
        max-width: 100%;
        color: #223d7f;
        margin-top: -25%;
        font-size: 25px;
        margin-bottom: -2%;
    }

    .pera-list {
        margin-top: 15%;
        margin-bottom: -15%;
    }

    .pera-slider1{
        position: relative;
        line-height: normal;
        font-size: 30px;
        width: 98%;
        color: #223d7f;
        margin-left: 1px;
    }

    .pera-slider2 {
        left: 9px;
        color: #223d7f;
        font-size: 17px !important;
    }

    .personel-slider1{
        position: relative;
        line-height: normal;
        font-size: 30px;
        width: 98%;
        color: #223d7f;
        margin-left: 1px;
        margin-top: -125%;
    }

    .perabutt{
        margin-left: -1%;
    }

    .iletisimbutt{
        margin-left: 0;
    }

    .finans-on {
        max-width: 148%;
        margin-left: -24%;
        margin-top: -40%;
        margin-right: -24%;
    }

    .buttontest1 {
        position: relative;
        margin-left: 14%;
    }

    .btn .btn-icon {
        margin-right: -70px;
        margin-left: -109px;
        margin-top: 0px;
        height: 36px;
        width: 36px;
        line-height: 34px;
        padding: 0;
    }

    .btn.btn-deneicon {
        margin-right: -70px;
        margin-left: -74px;
        margin-top: 0px;
        height: 36px;
        width: 36px;
        line-height: 34px;
        padding: 0;
    }

    .iletisim {
        margin-top: 10px;
        margin-left: -11%;
        color: #223d7f;
        width: 113%;
    }

    .iletisim-p{
        margin-left: 0%;
    }

    .iletisim-b{
        color: #223d7f;
    }

    .buttontest {
        font-family: "Nunito", sans-serif;
        background-color: #ffff;
        color: #223d7f;
        padding: 6px 12px 6px 12px;

    }

    .buttontest .text {
        font-family: "Nunito", sans-serif;
        width: 40px;
    }

    .imagetest {
        width: 165%;
        display: block;
        margin-left: -35%;
    }

    .imagetik {
        position: relative;
        left: 20px;
    }

    .back {
        background-image: url("../images/bulut12/onmuhasebearkaplan.png");
        background-repeat: repeat-y;
        display: block;
        width: 100%;
        height: auto;

    }

    .h1test {
        font-family: "Nunito", sans-serif;
        position: relative;
        color: #223d7f;
        width: 106%;
        font-size: 30px !important;
        margin-bottom: 0px;
    }

    .link {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-size: 20px;

    }

    .bg {
        font-family: "Nunito", sans-serif;
        background-color: #e4f2f9;
    }

    .edonusum-bg {
        font-family: "Nunito", sans-serif;
        background-color: #e4f2f9;
        margin-top: -60px;
    }

    .baslik {
        font-family: "Nunito", sans-serif;
        margin-top: -4%;
        margin-bottom: 4%;
        color: #223d7f;
    }

    .img-modul {
        margin-bottom: -6%;
    }

    .orange {
        background-color: #f77e66;
        font-family: "Nunito", sans-serif;
    }

    .p-uyeol {
        max-width: 98%;
    }

    .h3-uyeol {
        font-size: 23px;
    }

    .ptest {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 19px;
        line-height: normal;
        color: #596369;
        max-width: 345px;
    }

    .daha-ptest {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 15px;
        line-height: normal;
        color: #596369;
        text-align: left;
        margin-left: -18px;
    }

    .sektorler-ptest {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 19px;
        line-height: normal;
        color: #596369;
        text-align: center;
        margin-left: -7px;
    }

    .daha-h3test {
        margin-top: 2%;
        margin-left: -9%;
        color: #223d7f;
        text-align: left;
        width: 130%;
    }

    .pslider {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: #223d7f;
    }

    .section-title{
        margin-left: 15%;
    }

    .etext {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-weight: 500;
        font-size: 16px;
        line-height: normal;
        text-transform: none;
        text-align: left;
    }

    .border {
        border: none;
    }

    .cerceve {
        border: 1px solid white;
    }

    .rounded {
        border-radius: 6px !important;
        border: 1px solid white;
    }

    .bordertable {
        border: 1px solid #223d7f !important;
    }

    .bordertest {
        border: 1px solid #FABFB3 !important;
        min-width: 200px;
        min-height: 200px;
    }

    .gecis {
        font-family: "Nunito", sans-serif;
        font-weight: bold;
        color: #223d7f;
        font-size: 28px;
        line-height: normal;
        text-align: center;
        margin-top: -10%;
        margin-left: -1%;
    }

    .eftrimg {
        position: relative;
        left: -7%;
        width: 120%;
        height: auto;
    }

    .ednsm-btn {
        margin-top: -20%;
    }

    .eftrtext {
        text-align: left;
        font-size: 17px;
        margin-bottom: 10%;
    }

    #topnav .buy-button {
        float: right;
        line-height: 68px;
        padding: 3px 0;
        display: block;

    }

    #topnav .navigation-menu .has-submenu.active .menu-arrow {
        top: 22px;
        right: -4px;
    }

    #topnav .has-submenu .submenu .submenu-arrow {
        right: 65px;
        top: 9px;
    }

    #navigation {
        top: 70px;
        border-top: none;
        padding-bottom: 10px;
    }

    #topnav .navigation-menu>li>a {
        display: block;
        color: #3c4858;
        font-size: 16px;
        background-color: transparent !important;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: "Nunito", sans-serif;
        padding-left: 4px;
        padding-right: 8px;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: -15px;
    }

    #topnav .navigation-menu>li .submenu {
        margin-top: 10px;
        padding-left: 10px;
    }

    .col-lg-6 {
        position: relative;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .cardheight {
        height: 120px;
        border: none;
        background: none;
        margin-bottom: 7%;
    }

    .depocard {
        border: 1px solid white;
        margin-bottom: 0;
        border-radius: 10px;
    }

    .iletisim-mbl {
        margin-top: -145%;
    }

    .form-label {
        display: none;
    }

    .card-title {
        font-size: 27px;
        color: #223d7f;
    }

    .iletisim-card {
        margin-top: -20%;
    }

    .iletisim-img {
        width: 80px;
        height: 80px;
    }

    .ucretsizp-i {
        max-width: 198px;
        margin-left: 22%;
    }

    .iletisim-border {
        border: none;
    }

    .container {
        width: 93%;
    }

    .h6 {
        font-size: 15px;
    }

    .pera-kmlr-p{
        text-align: left;
    }

    .pera-kmlr{
        color: #223d7f;
    }

    .peramini-b{
        color: #223d7f;
    }

    .peramini{
        margin-left: -7%;
        width: 126%;
    }

    .mini{
        width: 126%;
    }

    .avantajlar-col{
        width: 123%;
        margin-left: -9%;
    }

    .p-kapsamli{
        font-size: 21px;
        color: #223d7f;
        width: 92%;
        margin-left: 5%;
        font-weight: 600;
        margin-bottom: -5%;
        margin-top: -10%;
    }

    .avantajlar{
        color: #223d7f;
        font-size: 35px;
        margin-bottom: -12%;
    }

    .pilk{
        font-size: 15px;
        width: 80%;
    }

    .tlfn-img{
        width: 95%;
    }

    .sektorler-h3test{
        font-size: 30px;
        color: #223d7f;
        font-weight: 600;
    }

    .fiyat-h3test {
        color: #223d7f;
        font-weight: 600;
        font-size: 27px;
    }

    .arrow-img{
        margin-bottom: -6%;
    }


    .footer-bar{
        margin-top: 0%;
    }

    .finans-slider1{
        position: relative;
        left: 0;
        line-height: normal;
        font-size: 30px;
        width: 98%;
        color: #223d7f;
    }

    .finans-h3test {
        max-width: 100%;
        color: #223d7f;
        margin-top: -5%;
        font-size: 25px;
        margin-bottom: -2%;
    }

    .pera-col6{
        margin-bottom: 10%;
    }

    .sss-col{
        display: none;
    }

    .accordion{
        margin-left: 5%;
        margin-right: 5%;
    }

    .eticaret-logolar{
        margin-top: 40%;
    }

    .nedir-row{
        margin-top: 10%;
        margin-left: -15%;
        width: 135%;
    }

    #topnav .navbar-toggle .lines {
        width: 25px;
        display: block;
        position: relative;
        margin: 29px -120px 24px;
        height: 18px;
    }

    .btn-icon{
        margin-left: -10% !important;
    }

    .card-left{
        margin-left: -16%;
    }

    .eticaretcont{
        width: 100%;
    }

    .perakenderow{
        margin-left: -22%;
    }

    .mainflip{
        margin-bottom: 5%;
    }

    .donercard{
        margin-top: -14%;
    }

    .cloud_animation{
        display: none;
    }

    .cerezrow{
        margin-top: 24%;
    }

    .cerez-h3test {
        color: #f77e66;
        font-size: 40px;
        margin-top: 11%;
        margin-bottom: 2%;
        text-align: center;
    }

    .pera-p, .cerez-p{
        font-size: 17px;
    }

    .pazaryericard{
        width: 90%;
    }

    .eticaretcol{
        padding-left: 5%;
    }

    .ednsmfatura{
        width: 100%;
    }

    .onmuh, .ednsm-baslik{
        color: #223d7f;
    }

}

@media (min-width: 440px) and (max-width: 767px) {
    h1 {
        font-size: 30px !important;
    }

    h2 {
        font-size: 33px !important;
    }

    h3 {
        font-size: 25px !important;
        width: 100%;
    }

    h5{
        font-size: 18px !important;
    }

    .logo {
        margin-left: 0;
        margin-top: 2%;
        letter-spacing: 1px;
        width: 120px;
    }
    .reg-img{
        display: none;
    }
    .reg-bil{
        justify-content: center;
        display: flex;
        border: none;
        width: 100%;
        top: -8%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }

    .btn-login {
        margin-top: 0px;
    }

    .menu-extras {
        margin-right: 0px;
    }

    .btn-icon{
        margin-left: -62%;
    }

    .slick-slide {
        background-size: 100% !important;
        background-position: right 10% bottom 35%;
        background-repeat: no-repeat;
        margin: 0px;
        padding: 0px;
        margin-top: 90px;
    }


    .slider-btn {
        position: relative;
        left: -25px;
    }

    .depo-slider-btn{
        margin-left: -6%;
    }

    .slider-desc {
        max-width: 463px;
        margin-left: -4%;
    }

    .btn-detayli {
        position: relative;
        margin-left: 0px;
    }

    .container-neden {
        margin-top: -1rem;
    }

    .ndnblt12 {
        margin-left: 1%;
    }

    .ndnblt-icon {
        width: 70px;
        height: 70px;
    }

    .main-slider .slides .bg-slider {
        height: 43vh;
        min-height: 400px;
        padding: 0px;
        margin: 0px;
    }

    .main-slider-i .slides-i .bg-slider-i {
        height: 110vh;
    }

    .verimerkezi {
        text-align: center;
    }

    .pveri {
        text-align: center;
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: #596369;
        max-width: 95%;
    }

    .nlr-p {
        text-align: left;
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 17px;
        line-height: normal;
        color: #596369;
        max-width: 100%;
        margin-left: -14%;
    }

    .bulut-nlr {
        border: none;
        width: 132%;
        margin-left: -14%;
    }

    .display-4 {
        font-size: 35px !important;
        text-align: justify;
        max-width: 100%;
    }

    .title-heading .para-desc {
        font-size: 15px !important;
        line-height: normal;
        max-width: 71%;
        color: #223d7f;
    }

    .btn-ed {
        padding: 5px 15px;
    }

    .btnstil {
        display: block !important;
        font-size: 15px;
        width: 225px;
        margin-left: 6%;
    }

    .btnstildetay {
        display: block !important;
        width: 150px;
        margin-left: -3%;
        font-weight: bold;
    }

    .btn-detayli {
        left: 40px;
    }

    .btn-store {
        display: none;
    }

    .h3test {
        margin-bottom: 7%;
        color: #223d7f;
    }

    .sektorler-h3test {
        margin-top: 2%;
        max-width: 0;
        margin-left: 0%;
        color: #223d7f;
        font-size: 45px;
        font-weight: 600;
    }

    .fiyat-h3test {
        color: #223d7f;
        font-weight: 600;
        font-size: 34px;
    }

    .h3test-nlr {
        color: #223d7f;
        font-weight: 600;
        font-size: 33px;
        margin-left: -8%;
    }

    .prs-h3test {
        margin-top: 2%;
        max-width: 303px;
        color: #223d7f;
    }

    .yada {
        display: none;
    }

    .h4test {
        text-align: center;
        margin-top: 12px;
    }

    .ekran {
        width: 295px;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .tekyerden {
        margin-left: -10px;
        margin-right: -49px;
    }

    .tekyerden-iki {
        margin-left: -15px;
        margin-right: -15px;
        border: none;
    }

    .eticaretfull {
        background-color: #e4f2f9;
        width: 98%;
        margin-left: 1%;
        border: none;
    }

    .text-l {
        text-align: left;
    }

    .dahaneler {
        position: relative;
        width: 90%;
        height: auto;
    }

    .dhimg {
        position: relative;
        margin-top: 6%;
        width: 110%;
        height: auto;
        margin-left: -8px;

    }

    .dn-iki {
        width: 140%;
        margin-left: -20%;
    }

    .pera-p, .cerez-p{
        font-size: 18px;
    }

    .deposayim {
        width: 98%;
        margin-left: 1%;
    }

    .dhimg-laptop {
        width: 78%;
        margin-left: -16px;
    }

    .dhimgpos {
        width: 100%;
        margin-left: 0;
        margin-bottom: -70px;
    }

    .responsiveapp {
        width: 115%;
        margin-left: -9%;
        margin-bottom: 5%;
        margin-top: 5%;
    }

    .dhimglogo {
        width: 90%;
        margin-left: 13%; 
        margin-bottom: 30px;
    }

    .dhimgbur {
        position: relative;
        margin-top: 6%;
        width: 110%;
        height: auto;
        margin-left: 5%;

    }

    .mor-btn {
        margin-left: 33%;
    }

    .play {
        margin-left: 29%;
        margin-bottom: 45px;
        margin-top: 15px;
    }

    .onmuha-link {
        padding-right: 8px;
        padding-left: 8px;
    }

    .btn-footer-nav {
        font-size: 14px;
    }

    .sss-img {
        width: 100%;
    }

    .modul-btn- {
        display: grid;
    }

    .modulbtn-ara {
        margin-bottom: -5%;
    }

    .pera-h3test {
        max-width: 100%;
        color: #223d7f;
        margin-top: -15%;
        font-size: 29px;
        margin-bottom: -2%;
    }

    .pera-slider1{
        position: relative;
        line-height: normal;
        font-size: 30px;
        width: 116%;
        color: #223d7f;
    }

    .pera-slider2 {
        left: 9px;
        font-size: 15px !important;
        line-height: normal;
        max-width: 77%;
        color: #223d7f;
    }

    .personel-slider1{
        position: relative;
        line-height: normal;
        font-size: 30px;
        width: 100%;
        color: #223d7f;
        margin-top: -20%;
    }

    .finans-on {
        margin-top: -13%;
        width: 130%;
        margin-left: -15%;
    }

    .finans-h3test {
        max-width: 72%;
        color: #223d7f;
        margin-top: -15%;
        font-size: 29px;
        margin-bottom: 3%;
    }

    .ekran{
        width: 120%;
        margin-left: -10%;
    }

    .btn-icon {
        margin-left: -50%;
    }

    .btn.btn-deneicon {
        margin-right: -70px;
        margin-left: -74px;
        margin-top: 0px;
        height: 36px;
        width: 36px;
        line-height: 34px;
        padding: 0;
    }

    .iletisim {
        margin-top: 10px;
        margin-left: 0px;

    }

    .buttontest {
        font-family: "Nunito", sans-serif;
        background-color: #ffff;
        color: #223d7f;
        padding: 6px 12px 6px 12px;

    }

    .buttontest .text {
        font-family: "Nunito", sans-serif;
        width: 33px;
    }

    .imagetest {
        width: 350px;
        height: 350px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .imagetik {
        position: relative;
        left: 20px;
    }

    .back {
        background-image: url("../images/bulut12/onmuhasebearkaplan.png");
        background-repeat: repeat-y;
        display: block;
        width: 100%;
        height: auto;

    }

    .h1test {
        font-family: "Nunito", sans-serif;
        position: relative;
        color: #223d7f;
        width: 124%;
        font-size: 30px !important;
    }

    .link {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-size: 15px;

    }

    .bg {
        font-family: "Nunito", sans-serif;
        background-color: #e4f2f9;
    }

    .edonusum-bg {
        font-family: "Nunito", sans-serif;
        background-color: #e4f2f9;
        margin-top: -60px;
    }

    .baslik {
        font-family: "Nunito", sans-serif;
        margin-top: 4%;
        margin-bottom: 4%;
        color: #223d7f;
        font-size: 15px;
    }

    .ndnblt-p{
        font-size: 16px;
    }

    .img-modul {
        margin-bottom: -3%;
    }

    .orange {
        background-color: #f77e66;
        font-family: "Nunito", sans-serif;
    }

    .p-uyeol {
        max-width: 98%;
        font-size: 18px;
    }

    .h3-uyeol {
        font-size: 23px;
        margin-left: -7%;
        width: 115%;
    }

    .ptest {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        max-width: 100%;
    }

    .daha-ptest {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 19px;
        line-height: normal;
        color: #596369;
        text-align: left;
        margin-left: -18px;
    }

    .sektorler-ptest {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        text-align: left;
        margin-left: 0%;
    }

    .daha-h3test {
        margin-top: 2%;
        margin-left: -25%;
        color: #223d7f;
    }

    .pslider {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: #223d7f;
    }

    .etext {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-weight: 500;
        font-size: 16px;
        line-height: normal;
        text-transform: none;
        text-align: center;
    }

    .nlr-button{
        margin-left: -7%;
    }

    .border {
        border: none;
    }

    .cerceve {
        border: 1px solid white;
    }

    .rounded {
        border-radius: 6px !important;
        border: 1px solid white;
    }

    .bordertable {
        border: 1px solid #223d7f !important;
    }

    .bordertest {
        border: 1px solid #FABFB3 !important;
        min-width: 200px;
        min-height: 200px;
    }

    .gecis {
        font-family: "Nunito", sans-serif;
        font-weight: bold;
        color: #223d7f;
        font-size: 28px;
        line-height: normal;
        text-align: center;
        margin-left: 12%;
        width: 77%;
    }

    .eftrimg {
        position: relative;
        left: -7%;
        width: 120%;
        height: auto;
    }

    .ednsm-btn {
        margin-top: -7%;
    }

    .ednsm-baslik{
        margin-left: 4%; 
    }

    .eftrtext {
        font-size: 18px;
    }

    .avantajlar-col{
        width: 110%;
    }

    #topnav .buy-button {
        float: right;
        line-height: 68px;
        padding: 3px 0;
        display: block;
        margin-left: -16%;
    }

    #topnav .navigation-menu .has-submenu.active .menu-arrow {
        top: 30%;
        right: 80%;
    }

    #topnav .has-submenu .submenu .submenu-arrow {
        display: none;
    }

    #navigation {
        padding-bottom: 4%;
    }

    #topnav .navigation-menu>li>a {
        display: block;
        color: #3c4858;
        font-size: 16px;
        background-color: transparent !important;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: "Nunito", sans-serif;
        padding-left: 4px;
        padding-right: 8px;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: -15px;
    }

    #topnav .navigation-menu>li .submenu {
        margin-top: 10px;
        padding-left: 10px;
    }

    #topnav .navigation-menu {
        list-style: none;
        margin-left: 9%;
        padding: 0;
    }

    .col-lg-6 {
        position: relative;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .cardheight {
        height: 100px;
        border: none;
        background: none;
        margin-bottom: 15%;
    }

    .depocard {
        border: 1px solid white;
        margin-bottom: 0;
        border-radius: 10px;
    }

    .iletisim-mbl {
        margin-top: -85%;
    }

    .form-label {
        display: none;
    }

    .card-title {
        font-size: 27px;
        color: #223d7f;
    }

    .iletisim-card {
        margin-top: -40%;
    }

    .iletisim-img {
        width: 80px;
        height: 80px;
    }

    .ucretsizp-i {
        max-width: 198px;
        margin-left: 22%;
    }

    .iletisim-border {
        border: none;
    }

    .container {
        width: 84%;
    }

    .h6 {
        font-size: 15px;
    }

    .buttontest1 {
        position: relative;
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        padding: 6px 12px 6px 12px;

    }

    .buttontest1hiza {
        position: relative;
        right: -10%;
        margin-top: -3%;
    }

    .ucretsizp{
        width: 102%;
    }

    .pera-col6{
        margin-top: 15%;
        margin-left: -3%;
        margin-bottom: 15%;
    }

    .pera-kmlr{
        text-align: left;
        width: 99%;
        color: #223d7f;
        margin-top: -4%;
        margin-left: -6%;
    }

    .pera-kmlr-p{
        text-align: left;
        margin-left: -6%;
        width: 110%;
        font-size: 18px;
    }

    .tlfn-img{
        margin-left: 15%;
    }


    .peramini-b{
        color: #223d7f;
    }

    .avantajlar{
        color: #223d7f;
        font-size: 40px;
        margin-bottom: -3%;
    }

    .p-kapsamli{
        font-size: 25px;
        color: #223d7f;
        width: 91%;
        margin-left: 5%;
        font-weight: 600;
    }

    .modul-btn{
        margin-bottom: 8%;
    }

    .peramini, .mini{
        margin-left: 5%;
        width: 109%;
        margin-top: 10%;
    }

    .pilk{
        font-size: 15px;
    }

    .bg-light{
        margin-top: 0;
    }

    .uyeolbuton{
        margin-bottom: 2%;
    }

    .finans-slider1{
        font-size: 30px !important;
        color: #223d7f;
        line-height: normal;
        width: 114%;
    }

    .iletisim-b{
        color: #223d7f;
    }

    .sss-col{
        display: none;
    }

    .accordion{
        margin-left: 5%;
        margin-right: 5%;
    }

    .eticaret-logolar{
        margin-top: 20%;
    }

    .nedir-row{
        margin-top: 10%;
    }

    .d-lg-block{
        display: block !important;
    }

    #topnav .navbar-toggle .lines {
        width: 25px;
        display: block;
        position: relative;
        margin: 29px -115px 24px;
        height: 18px;
    }

    .btn-icon{
        margin-left: 2%;
    }

    .mainflip{
        margin-bottom: 5%;
    }

    .donercard{
        margin-top: -9%;
    }

    .cloud_animation{
        display: none;
    }

    .link{
        font-size: 19px;
    }

    .perabutt{
        margin-bottom: 10%;
    }

    .perakenderow{
        width: 150%;
        margin-left: -32%;
    }

    .cerezrow{
        margin-top: 17%;
    }

    .cerez-h3test {
        color: #f77e66;
        font-size: 40px;
        margin-top: 11%;
        margin-bottom: 2%;
        text-align: center;
    }

    .pera-p, .cerez-p{
        font-size: 17px;
    }

    .pazaryericard{
        width: 100%;
    }
    
    .onmuh{
        color: #223d7f;
        width: 80%;
        text-align: center;
        margin-left: 11%;
    }

    .title{
        font-size: 18px !important;
    }

    .ednsmfatura{
        width: 90%;
        margin-left: 8%;
    }

}

@media (min-width: 375px) and (max-width: 414px) {
    .onikipro {
        width: 98%;
        margin-left: 1px;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .onikipro-p {
        width: 110%;
    }
    .reg-img{
        display: none;
    }
    .reg-bil{
        justify-content: center;
        display: flex;
        border: none;
        width: 10%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    .userinvitation{
        justify-content: center;
        width: 70%;
        margin-top: -10%;
        padding-right: 1px;
        padding-left: 1px;
        margin-bottom: 15%;
    }
    .userinvitationimage{
        justify-content: center;
        border: none;
        width: 80%;
        position: relative;
        left: -20%;
    }
    .userinvitationword{
        justify-content: center;
        border: none;
        width: 80%;
        position: relative;
        left: -20%;
    }
    .footerstyle{
        position: relative;
        top: 5%;
    }

}

@media (min-width: 360px) and (max-width: 414px) {
    .iletisim-mbl {
        margin-bottom: -120px;
    }

    .cerceve {
        border: 1px solid white;
    }
    .reg-img{
        display: none;
    }
    .reg-bil{
        justify-content: center;
        display: flex;
        border: none;
        width: 10%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    .userinvitationimage{
        justify-content: center;
        border: none;
        width: 80%;
        position: relative;
        left: -20%;
    }
    .userinvitationword{
        justify-content: center;
        border: none;
        width: 80%;
        position: relative;
        left: -20%;
    }
    .footerstyle{
        position: relative;
        top: 5%;
    }
}

@media (min-width: 712px) and (max-width: 1138px){
    .slick-list{
        margin-bottom: 6%;
    }

    .ednsm-btn{
        margin-top: -10%;
    }
    .reg-img{
        display: none;
    }
    .reg-bil{
        top: 1%;
        justify-content: center;
        display: flex;
        border: none;
        width: 100%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    .footer{
        margin-top: -10%;
    }
}

@media (min-width: 768px){
    .smallscreen{
        display: none;
    }
    .reg-bil{
        justify-content: center;
        display: flex;
        border: none;
        width: 100%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    .userinvitationimage{
        justify-content: center;
        border: none;
        width: 70%;
        position: relative;
        
    }
    .userinvitationword{
        justify-content: center;
        border: none;
        width: 100%;
        position: relative;
       
    }
    .footerstyle{
        position: relative;
        top: 5%;
    }
}

@media (max-width: 768px){
    .bigscreen{
        display: none;
    }

    .mblrowbir{
        margin-left: 7%;
    }

    .mblrowuc{
        margin-right: -7%;
    }
    .reg-img{
        display: none;
    }
    .reg-bil{
        justify-content: center;
        display: flex;
        border: none;
        width: 100%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    .userinvitationimage{
        justify-content: center;
        border: none;
        width: 70%;
        position: relative;
        
    }
    .userinvitationword{
        justify-content: center;
        border: none;
        width: 100%;
        position: relative;
       
    }
    .footerstyle{
        position: relative;
        top: 5%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .logo {
        position: relative;
        left: -2%;
        margin-top: 2%;
    }
    .reg-img{
        display: none;
    }
    .reg-bil{
        justify-content: center;
        display: flex;
        left: -80%;
        border: none;
        width: 200%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }

    .btn .btn-icon {
        margin-right: 2%;
        margin-top: 7%;
        height: 36px;
        width: 36px;
        line-height: 34px;
        padding: 0;
    }

    .slick-slide {
        background-size: 123% !important;
        background-position: right 13% bottom 42%;
        background-repeat: no-repeat;
        margin: 0px;
        padding: 0px;

    }


    .slider-top {
        margin-left: -2%;
    }

    .personel-slider-btn {
        position: relative;
        margin-left: 8%;    }

    .slider-btn {
        position: relative;
        left: -20px;
    }

    .depo-slider-btn {
        position: relative;
        margin-left: 5%;
    }

    .main-slider .slides .bg-slider {
        min-height: 715px;
        height: 55vh;
        margin: 0px;
        padding: 0px;
    }

    .h3test {
        margin-top: -3%;
        max-width: 500px;
        color: #223d7f;
        text-align: center;
        margin-left: 10%;
    }
    
    .h3test-nlr {
        color: #223d7f;
        font-weight: 600;
        font-size: 35px;
    }

    .display-4 {
        font-size: 40px !important;
        text-align: initial;
        line-height: normal;
        margin-top: 20%;
        margin-left: 1%;
    }

    .title-heading .para-desc {
        font-size: 17px !important;
        line-height: normal;
        max-width: 400px;
        color: #223d7f;
        margin-left: -2%;
    }

    .title-heading {
        display: -ms-inline-grid;
        margin-left: -9%;
    }

    .btnstil {
        display: block !important;
        font-size: 17px;
        width: 250px;
        margin-left: 1%;
    }

    .btnstildetay {
        display: block !important;
        width: 150px;
        margin-left: 1%;
        font-weight: bold;
    }

    .onmuh {
        position: relative;
        text-align: left;
        width: 71%;
        color: #223d7f;
    }

    .eticarettext{
        font-size: 18px;
        text-align: left;
    }

    .avatar{
        width: 85%;
    }

    .buttontest1 {
        position: relative;
        right: -10%;
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        padding: 6px 12px 6px 12px;
        
    }

    .btn-ed{
        font-size: 18px !important;
    }

    .buttontest1hiza {
        position: relative;
        right: -8%;
        margin-top: -1%;
    }

    .etext {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-weight: 500;
        font-size: 16px;
        line-height: normal;
        text-transform: none;
        left: 21%;
        position: relative;
        max-width: 74%;
        text-align: center;
    }

    .ndnblt12 {
        margin-top: -5%;
        margin-left: 7%;
    }

    .col-md-2 {
        max-width: 100%;
    }

    .ndnblt-icon {
        width: 50%;
        height: 50%;
    }

    .ndnblt-p{
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        line-height: normal;
        color: #3c4858;
        text-align: center;
        max-width: 90%;
        margin-left: 8%;
    }

    .pveri {
        text-align: center;
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        max-width: 71%;
    }

    .daha-ptest{
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        text-align: center;
        margin-left: 5%;
        width: 90%;
    }

    .daha-h3test{
        color: #223d7f;
    }

    .iletisim {
        margin-top: 10px;
        margin-left: 0px;

    }

    .mobil {
        margin-top: 10px;
    }

    .dahaneler {
        position: relative;
        width: 100%;
        height: auto;
    }

    .dhimg {
        position: relative;
        margin-top: 6%;
        width: 100%;
        height: auto;
    }

    .dhimg-laptop {
        width: 115%;
    }

    .buttontest {
        font-family: "Nunito", sans-serif;
        background-color: #ffff;
        color: #223d7f;
        padding: 6px 12px 6px 12px;
    }

    .buttontest .text {
        font-family: "Nunito", sans-serif;
        width: 40px;
    }

    .imagetest {
        width: 482px;
        height: 473px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .imagetik {
        position: relative;
        left: 20px;
    }

    .back {
        background-image: url("../images/bulut12/onmuhasebearkaplan.png");
        background-repeat: repeat-y;
        display: block;
        width: 100%;
        height: auto;

    }

    .h1test {
        font-family: "Nunito", sans-serif;
        position: relative;
        left: -3%;
        line-height: normal;
        color: #223d7f;
        width: 138%;
        font-size: 30px;
    }

    .link {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-size: 20px;
    }

    .bg {
        font-family: "Nunito", sans-serif;
        background-color: #e4f2f9;

    }

    .baslik {
        font-family: "Nunito", sans-serif;
        margin-bottom: 4%;
        margin-top: 3%;
        color: #223d7f;
        font-size: 17px;
    }

    .orange {
        background-color: #f77e66;
        font-family: "Nunito", sans-serif;
    }

    .ptest {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        text-align: center;
    }

    .pslider {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: #223d7f;
    }

    .bordertable {
        border: 1px solid #223d7f !important;
    }

    .bordertest {
        border: 1px solid #FABFB3 !important;
        min-width: 200px;
        min-height: 200px;
    }

    .gecis {
        font-family: "Nunito", sans-serif;
        font-weight: bold;
        color: #223d7f;
        font-size: 36px !important;
        line-height: normal;
        text-align: center;
        margin-top: -12%;
        width: 66%;
        margin-left: 18%;
    }

    .text-l {
        text-align: center;
        margin-left: -5%;
    }

    .cerceve {
        border: 1px solid white;
    }

    .ucretsizp{
        margin-left: 13%;
    }

    .card{
        border: none;
        background-color: transparent;
        width: 100%;
    }

    .card-stil{
        width: 98%;
    }

    .pera-col6{
        margin-bottom: 7%;
        margin-left: -5%;
    }

    .pera-kmlr{
        text-align: left;
        width: 95%;
        color: #223d7f;
        margin-top: -8%;
        margin-left: 0;
    }

    .pera-kmlr-p{
        text-align: left;
        margin-left: 0;
        width: 90%;
        font-size: 18px;
    }

    .peramini-b{
        color: #223d7f;
        margin-top: 4%;
        width: 98%;
    }

    .pera-slider1{
        position: relative;
        left: -3%;
        line-height: normal;
        font-size: 34px;
        width: 134%;
        color: #223d7f;
    }

    .avantajlar{
        color: #223d7f;
        font-size: 40px;
        margin-bottom: -3%;
    }

    .avantajlar-p{
        font-size: 15px;
    }

    .modul-btn{
        margin-left: 8%;
    }

    .modulbtn-ara{
        margin-bottom: -4%;
    }

    .pera-h3test{
        width: 85%;
        color: #223d7f;
        margin-left: 0;
        text-align: left;
        font-size: 35px;
    }

    .pazaryericard{
        width: 100%;
    }

    .finans-h3test{
        color: #223d7f;
        font-size: 35px;
        width: 74%;
        margin-bottom: -1%;
    }

    .finans-on{
        margin-left: 0;
    }

    .finans-slider1{
        color: #223d7f;
        line-height: normal;
        position: relative;
        margin-left: -3%;
        width: 133%;
        font-size: 34px;
    }

    .personel-slider1{
        color: #223d7f;
        line-height: normal;
        position: relative;
        margin-left: 6%;
        width: 66%;
        font-size: 34px;
    }

    .bg-light{
        padding-bottom: 5%;
    }

    .p-kapsamli{
        font-size: 25px;
        color: #223d7f;
        width: 91%;
        margin-left: 5%;
        font-weight: 600;
    }

    .play{
        margin-left: 48%;
        margin-bottom: 7%;
    }

    .sektorler-h3test{
        font-size: 40px;
        color: #223d7f;
        font-weight: 600;
    }

    .fiyat-h3test {
        color: #223d7f;
        font-weight: 600;
        font-size: 34px;
    }

    .footer-bar{
        margin-top: 0%;
    }

    .pera-slider2{
        font-size: 18px;
        line-height: normal;
        max-width: 98%;
        color: #223d7f;
        margin-left: -2%;
    }

    .personel-slider2{
        font-size: 18px;
        line-height: normal;
        max-width: 70%;
        color: #223d7f;
        margin-left: 6%;
    }

    .cardheight {
        height: 150px;
        border: none;
        background: none;
    }

    .ekran{
        width: 100%;
        margin-bottom: 0;
    }

    .depocard{
        width: 114%;
    }

    .bulut-nlr{
        width: 100%;
    }

    .iletisim-b{
        color: #223d7f;
    }

    .menu-extras{
        margin-right: -14%;
    }

    .btn-icon{
        margin-left: -35%;
    }

    .sss-col{
        display: none;
    }

    .eticaret-logolar{
        margin-top: 5%;
    }

    #navigation{
        position: absolute;
        top: 100%;
        margin-left: 70%;
        width: 30%;
    }

    .eftrimg{
        width: 100%;
        margin-top: -3%;
    }

    .ednsm-btn{
        margin-top: -15%;
    }

    .sss-img{
        display: none;
    }

    .sektorler-ptest{
        font-size: 18px;
        text-align: center;
        width: 70%;
        margin-left: 14%;
    }

    .peramini-p{
        font-size: 18px;
    }

    .p-uyeol{
        font-size: 18px;
    }

    .title-heading .heading{
        margin-left: 8%;
    }

    .heading-p{
        margin-left: 41px;
    }

    .heading-btn{
        margin-left: 41px;
    }

    .d-lg-block{
        display: block !important;
    }

    #topnav .navbar-toggle .lines {
        width: 25px;
        display: block;
        position: relative;
        margin: 29px -100px 24px;
        height: 18px;
    }

    .donercard-row{
        width: 123%;
        margin-left: -11%;
    }
    
    h5{
        font-size: 18px !important;
    }

    h4{
        font-size: 20px !important;
    }

    .nlr-p{
        font-size: 18px;
    }

    .cloud_animation{
        display: none;
    }

    .mobilrow{
        margin-left: -18%;
        margin-right: -18%;
    }

    .responsiveapp {
        width: 115%;
        margin-left: -5%;
        margin-bottom: 5%;
        margin-top: 5%;
    }

    .ednsmfatura{
        width: 90%;
        margin-left: 8%;
    }

    .cerezrow{
        margin-top: 12%;
    }

    .cerez-h3test {
        color: #f77e66;
        font-size: 40px;
        margin-top: 11%;
        margin-bottom: 2%;
        text-align: center;
    }

    .pera-p, .cerez-p{
        font-size: 17px;
    }

    .ednsm-baslik{
        color: #223d7f;
        width: 80%;
        text-align: center;
        margin-left: 16%;
    }

    .btnstil{
        margin-left: 8%;
    }

    .dhimglogo{
        width: 150%;
    }
    .userinvitationimage{
        justify-content: center;
        border: none;
        width: 70%;
        position: relative;
        
    }
    .userinvitationword{
        justify-content: center;
        border: none;
        width: 100%;
        position: relative;
       
    }
    .footerstyle{
        position: relative;
        top: 5%;
    }
}

@media (width: 712px) and (height:1138px){
    .main-slider .slides .bg-slider{
        height: 30vh;
    }

    .slider-top{
        margin-top: 10%;
    }
    .reg-img{
        display: none;
    }
    .reg-bil{
        justify-content: center;
        display: flex;
        left: -80%;
        border: none;
        width: 150%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    .userinvitationimage{
        justify-content: center;
        border: none;
        width: 70%;
        position: relative;
        
    }
    .userinvitationword{
        justify-content: center;
        border: none;
        width: 100%;
        position: relative;
       
    }
    .footerstyle{
        position: relative;
        top: 5%;
    }

}

@media (width: 768px) and (height:1024px){
    .main-slider .slides .bg-slider{
        height: 60vh;
    }
    .reg-img{
        display: none;
    }
    .reg-bil{
        justify-content: center;
        display: flex;
        left: -80%;
        border: none;
        width: 150%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    .userinvitationimage{
        justify-content: center;
        border: none;
        width: 70%;
        position: relative;
        
    }
    .userinvitationword{
        justify-content: center;
        border: none;
        width: 100%;
        position: relative;
       
    }
    .footerstyle{
        position: relative;
        top: 5%;
    }
}

@media (width: 896px) and (height:414px){
    .main-slider .slides .bg-slider{
        height: 125vh;
    }
    .userinvitationimage{
        justify-content: center;
        border: none;
        width: 70%;
        position: relative;
        
    }
    .userinvitationword{
        justify-content: center;
        border: none;
        width: 100%;
        position: relative;
       
    }
    .footerstyle{
        position: relative;
        top: 5%;
    }
}

@media (width: 800px) {
    .main-slider .slides .bg-slider{
        height: 60vh;
    }
    .reg-img{
        display: none;
    }
    .reg-bil{
        justify-content: center;
        display: flex;
        left: -80%;
        border: none;
        width: 150%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    .userinvitationimage{
        justify-content: center;
        border: none;
        width: 70%;
        position: relative;
        
    }
    .userinvitationword{
        justify-content: center;
        border: none;
        width: 100%;
        position: relative;
       
    }
    .footerstyle{
        position: relative;
        top: 5%;
    }
}

@media (width:820px) and (height:1180px){
    .main-slider .slick-slide .bg-slider{
        height: 60vh;
    }
    .reg-img{
        display: none;
    }
    .reg-bil{
        justify-content: center;
        display: flex;
        left: -80%;
        border: none;
        width: 150%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    .userinvitationimage{
        justify-content: center;
        border: none;
        width: 70%;
        position: relative;
        
    }
    .userinvitationword{
        justify-content: center;
        border: none;
        width: 100%;
        position: relative;
       
    }
    .footerstyle{
        position: relative;
        top: 5%;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .logo {
        position: relative;
        left: 1%;
    }
    .reg-img{
        position: relative;
        background-color: transparent !important;
        width: 80%;
        height: auto;
        left: -8%;
        top: 12%;
    }
    .reg-bil{
        position: relative;
        left: -35%;
        border: none;
        width: 127%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }

    .btn .btn-icon {
        margin-right: 2%;
        margin-top: -10%;
        height: 36px;
        line-height: 34px;
        padding: 0;
        margin-left: 90%;
    }

    .buy-button {
        margin-right: 3%;
    }

    .slick-slide {
        background-size: 124% !important;
        background-position: right 13% bottom 27%;
        background-repeat: no-repeat;
        margin: 0px;
        padding: 0px;
        margin-top: 90px;
    }

    .slider-btn {
        position: relative;
        left: -20px;
    }

    .personel-slider-btn {
        position: relative;
        margin-left: -2%;
    }

    .depo-slider-btn{
        margin-left: -3%;
    }

    .main-slider .slides .bg-slider {
        height: 60vh;
        margin: 0px;
        padding: 0px;
    }

    .h3test {
        margin-top: 5%;
        max-width: 500px;
        color: #223d7f;
        text-align: center;
        margin-left: 20%;
    }
    
    .h3test-nlr {
        color: #223d7f;
        font-weight: 600;
        font-size: 40px;
    }

    .display-4 {
        font-size: 40px !important;
        text-align: initial;
        line-height: normal;
        margin-top: 20%;
        margin-left: 1%;
    }

    .title-heading .para-desc {
        font-size: 18px !important;
        line-height: normal;
        max-width: 400px;
        color: #223d7f;
        margin-left: -2%;
    }

    .title-heading {
        display: -ms-inline-grid;
        margin-left: 2%;
    }

    .btnstil {
        display: block !important;
        font-size: 17px;
        width: 250px;
        margin-left: 1%;
    }

    .btnstildetay {
        display: block !important;
        width: 150px;
        margin-left: 1%;
        font-weight: bold;
    }

    .onmuh {
        position: relative;
        margin-top: 20px;
        text-align: left;
    }

    .etext {
        position: relative;

    }

    .buttontest1 {
        position: relative;
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        padding: 6px 12px 6px 12px;

    }

    .btn-ed{
        width: 108%;
    }

    .buttontest1hiza {
        position: relative;
        right: -5%;
        margin-top: -1%;
    }

    .etext {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-weight: 500;
        font-size: 16px;
        line-height: normal;
        text-transform: none;
    }

    .ndnblt12 {
        margin-top: 10%;
        margin-left: 5%;
    }

    .ndnblt-p{
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        line-height: normal;
        color: #3c4858;
        text-align: center;
        max-width: 66%;
        margin-left: 18%;
    }

    .pveri {
        text-align: center;
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        max-width: 60%;
    }

    .daha-ptest{
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        text-align: center;
        margin-left: 100px;
        max-width: 78%;
    }

    .col-md-2 {
        max-width: 100%;
    }

    .ndnblt-icon {
        width: 60%;
        height: 60%;
    }

    .iletisim {
        margin-top: 10px;
        margin-left: 0px;

    }

    .mobil {
        margin-top: 10px;
    }

    .dahaneler {
        position: relative;
        width: 100%;
        height: auto;
    }

    .dhimg {
        position: relative;
        margin-top: 6%;
        width: 100%;
        height: auto;
    }

    .dhimg-laptop {
        width: 115%;
    }

    .buttontest {
        font-family: "Nunito", sans-serif;
        background-color: #ffff;
        color: #223d7f;
        padding: 6px 12px 6px 12px;
    }

    .buttontest .text {
        font-family: "Nunito", sans-serif;
        width: 40px;
    }

    .imagetest {
        width: 482px;
        height: 473px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .imagetik {
        position: relative;
        left: 20px;
    }

    .back {
        background-image: url("../images/bulut12/onmuhasebearkaplan.png");
        background-repeat: repeat-y;
        display: block;
        width: 100%;
        height: auto;

    }

    .h1test {
        font-family: "Nunito", sans-serif;
        position: relative;
        left: -2%;
        line-height: normal;
        color: #223d7f;
        width: 60%;
        font-size: 35px;
    }

    .link {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-size: 16px;
    }

    .bg {
        font-family: "Nunito", sans-serif;
        background-color: #e4f2f9;

    }

    .baslik {
        font-family: "Nunito", sans-serif;
        margin-top: -4%;
        margin-bottom: 4%;
        color: #223d7f;
        font-size: 17px;
    }

    .orange {
        background-color: #f77e66;
        font-family: "Nunito", sans-serif;
    }

    .ptest {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        text-align: center;
    }

    .p-uyeol{
        font-size: 18px;
    }

    .pslider {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: #223d7f;
    }

    .etext {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-weight: 500;
        font-size: 17px;
        line-height: normal;
        text-transform: none;
        text-align: left;
    }

    .ednsm-baslik {
        position: relative;
        left: 4%;
        top: 10%;
        color: #223d7f;
    }

    .onmuh{
        color: #223d7f;
    }

    .nlr-p{
        font-size: 19px;
    }

    .bordertable {
        border: 1px solid #223d7f !important;
    }

    .bordertest {
        border: 1px solid #FABFB3 !important;
        min-width: 200px;
        min-height: 200px;
    }

    .gecis {
        font-family: "Nunito", sans-serif;
        font-weight: bold;
        color: #223d7f;
        font-size: 40px;
        line-height: normal;
        text-align: center;
        margin-top: 5%;
    }

    .nedir-row{
        margin-left: 6%;
    }

    .text-l {
        text-align: center;
        width: 83%;
    }

    .cerceve {
        border: 1px solid white;
    }

    #topnav .navigation-menu>li>a {
        display: block;
        color: #3c4858;
        font-size: 14px;
        background-color: transparent !important;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: "Nunito", sans-serif;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: -15px;
    }

    #topnav .navigation-menu>li {
        margin-left: 2%;
        margin-right: -4%;
    }

    #topnav .navigation-menu {
        list-style: none;
        margin-right: 14%;
        padding: 0;
    }

    .btn-login {
        margin-left: -150%;
    }

    .sektorler-ptest{
        text-align: center;
        width: 56%;
        margin-left: 22%;
        font-size: 18px;
    }

    .verimerkezi {
        text-align: center;
        color: #223d7f;
    }

    .card{
        border: none;
        background-color: transparent;
    }

    .pera-list{
        margin-top: 8%;
    }

    .pera-h3test{
        width: 70%;
        color: #223d7f;
        margin-left: 15%;
        text-align: center;
        font-size: 36px;
    }

    .pera-slider2{
        font-size: 18px;
        line-height: normal;
        max-width: 400px;
        color: #223d7f;
        margin-left: -2%;
    }

    .pera-kmlr{
        margin-top: -6%;
        max-width: 100%;
        color: #223d7f;
        text-align: center;
        margin-left: 7%;
    }

    .pera-p, .cerez-p{
        text-align: left;
        max-width: 100%;
        margin-left: -1%;
    }

    .title{
        font-size: 16px !important;
    }

    .perabutt{
        margin-left: 40%;
    }

    .pera-kmlr-p{
        margin-left: 4%;
        width: 90%;
        font-size: 18px;
    }
    
    .bg-light{
        padding-bottom: 5%;
    }

    .peramini-b{
        color: #223d7f;
        width: 111%;
    }

    .avantajlar{
        color: #223d7f;
        font-size: 40px;
        margin-bottom: -3%;
    }

    .p-kapsamli{
        font-size: 25px;
        color: #223d7f;
        width: 69%;
        margin-left: 15%;
        font-weight: 600;
    }

    .pera-slider1{
        position: relative;
        left: -2%;
        line-height: normal;
        font-size: 40px;
        width: 64%;
        color: #223d7f;
    }

    .personel-slider1{
        position: relative;
        left: -2%;
        line-height: normal;
        font-size: 40px;
        width: 64%;
        color: #223d7f;
    }

    .modulbtn-ara{
        margin-bottom: 1%;
    }

    .sektorler-h3test{
        font-size: 40px;
        color: #223d7f;
        font-weight: 600;
    }

    .fiyat-h3test {
        color: #223d7f;
        font-weight: 600;
        font-size: 34px;
    }

    .cardheight {
        height: 150px;
        border: none;
        background: none;
    }

    .finans-slider1{
        font-size: 40px;
        line-height: normal;
        color: #223d7f;
        width: 69%;
        margin-left: -2%;
    }

    .ekran{
        width: 90%;
    }

    .sss-img{
        width: 134%;
        margin-left: -17%;
    }

    .finans-h3test{
        color: #223d7f;
        font-size: 36px;
        width: 48%;
        margin-top: 5%;
        margin-bottom: 0px;
        margin-left: 24%;
        text-align: center;
    }

    .finans-list{
        margin-top: 5%;
    }

    .iletisim-b{
        color: #223d7f;
    }

    #topnav .navigation-menu .has-submenu.active .menu-arrow {
        top: 40%;
        right: 4%;
    }

    .daha-h3test{
        color: #223d7f;
    }

    .eftrimg{
        width: 90%;
        margin-left: -2%;
    }

    .ednsm-btn{
        margin-top: -1%;
    }

    .peramini-p{
        text-align: left;
    }

    .title-heading .heading{
        margin-left: -2px;
    }

    .responsiveapp {
        width: 110%;
        margin-left: 6%;
        margin-bottom: 5%;
        margin-top: 5%;
    }

    .cerezrow{
        margin-top: 8%;
    }

    .cerez-h3test {
        color: #f77e66;
        font-size: 40px;
        margin-top: 7%;
        margin-bottom: 2%;
        text-align: center;
    }

    .pera-p, .cerez-p{
        font-size: 17px;
    }

    .ednsmfatura{
        width: 105%;
        margin-top: 10%;
    }
    
    .userinvitationimage{
        justify-content: center;
        border: none;
        width: 70%;
        position: relative;
        
    }
    .userinvitationword{
        justify-content: center;
        border: none;
        width: 100%;
        position: relative;
       
    }
    .footerstyle{
        position: relative;
        top: 5%;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    .title-heading{
        margin-left: 1%;
    }
    .logo{
        margin-left: 0%;
        margin-top: 1%;
    }
    .btn .btn-icon{
        margin-right: 165px;
    }
    .slider-btn {
        margin-left: -3%;
    }

    .personel-slider-btn {
        margin-left: -3%;
    }  

    .depo-slider-btn {
        margin-left: -2%;
    }
    .reg-img{
        position: relative;
        background-color: transparent !important;
        width: 80%;
        height: auto;
        left: -8%;
        top: 12%;
    }
    .reg-bil{
        position: relative;
        left: -35%;
        border: none;
        width: 145%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    
    .slider-top{
        margin-top: -15%;
    }

    #topnav .navigation-menu>li>a {
        display: block;
        color: #3c4858;
        font-size: 14px;
        background-color: transparent !important;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: "Nunito", sans-serif;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: -15px;
    }

    #topnav .navigation-menu>li {
        float: left;
        display: block;
        position: relative;
        margin-left: -2%
    }

    .btn-login {
        margin-top: 0;
        margin-bottom: 8%;
    }

    .col-md-2 {
        max-width: 100%;
    }

    .ndnblt-icon {
        width: 60px;
        height: 60px;
    }

    .main-slider .slides .bg-slider {
        height: 50vh;
        margin: 0px;
        padding: 0px;
        min-height: 650px;
    }

    .slick-slide {
        background-size: 126% !important;
        background-position: 80% 52%;
        background-repeat: no-repeat;
        margin: 0px;
        padding: 0px;
        margin-top: 90px;
    }

    .display-4 {
        font-size: 55px !important;
        text-align: inherit;
        line-height: normal;
    }

    .title-heading .para-desc {
        font-size: 18px !important;
        line-height: normal;
        margin-left: -2.3%;
        color: #223d7f;
    }

    .home-slider1{
        max-width: 64%;
        font-family: "Nunito", sans-serif;
        position: relative;
        line-height: normal;
        color: #223d7f;
    }

    .para-desc {
        max-width: 490px;
    }

    .h3test {
        color: #223d7f;
        font-size: 35px;
    }

    .h3test-nlr {
        color: #223d7f;
        font-weight: 600;
        font-size: 35px;
    }

    .p-uyeol{
        font-size: 18px;
    }

    .buttontest {
        font-family: "Nunito", sans-serif;
        background-color: #ffff;
        color: #223d7f;
        padding: 6px 12px 6px 12px;
    }

    .buttontest .text {
        font-family: "Nunito", sans-serif;
        width: 40px;
    }

    .imagetest {
        width: 482px;
        height: 473px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .imagetik {
        position: relative;
        left: 20px;
    }

    .back {
        background-image: url("../images/bulut12/onmuhasebearkaplan.png");
        background-repeat: repeat-y;
        display: block;
        width: 100%;
        height: auto;

    }

    .h1test {
        font-family: "Nunito", sans-serif;
        position: relative;
        left: -3%;
        line-height: normal;
        color: #223d7f;
        font-size: 38px;
    }

    .link {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-size: 16px;
    }

    .bg {
        font-family: "Nunito", sans-serif;
        background-color: #e4f2f9;

    }

    .baslik {
        font-family: "Nunito", sans-serif;
        margin-top: 4%;
        margin-bottom: 4%;
        color: #223d7f;
        font-size: 21px;
    }

    .orange {
        background-color: #f77e66;
        font-family: "Nunito", sans-serif;
    }

    .daha-h3test{
        color: #223d7f;
    }

    .ptest {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: normal;
        color: #596369;
        text-align: center;
    }

    .pveri {
        text-align: center;
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        max-width: 60%;
    }

    .ndnblt-p {
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        line-height: normal;
        color: #3c4858;
        text-align: center;
        max-width: 60%;
        margin-left: 20%;
    }

    .ucretsizp{
        margin-left: 13%;
    }

    .daha-ptest{
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        text-align: center;
        margin-left: 13%;
        max-width: 77%;
    }

    .bulut-nlr{
        width: 120%;
    }

    .bulut-nlrrow{
        margin-left: -5%;
    }

    .dahaneler {
        position: relative;
        width: 100%;
        height: auto;
    }

    .dhimg {
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 6%;
    }

    .pslider {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: #223d7f;
    }

    .onmuh {
        text-align: center;
        color: #223d7f;
        width: 60%;
        margin-left: 21%;
    }

    .eticarettext{
        font-size: 18px;
        width: 85%;
        text-align: center;
        margin-left: 5%;
    }

    .eticaretcol{
        padding-left: 12%;
    }

    .buttontest1 {
        position: relative;
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        padding: 6px 12px 6px 12px;
        margin-top: -3%;

    }

    .buttontest1hiza {
        position: relative;
        right: -5%;
        margin-top: -4.5%;
    }

    .etext {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-weight: 500;
        font-size: 18px;
        line-height: normal;
        text-transform: none;
        text-align: left;
    }

    .bordertable {
        border: 1px solid #223d7f !important;
    }

    .bordertest {
        border: 1px solid #FABFB3 !important;
        min-width: 200px;
        min-height: 200px;
    }

    .gecis {
        font-family: "Nunito", sans-serif;
        font-weight: bold;
        color: #223d7f;
        font-size: 40px;
        line-height: normal;
        text-align: center;
        margin-top: 5%;
        width: 73%;
        margin-left: 15%;
    }

    .cerceve {
        border: 1px solid white;
    }

    h1 {
        font-size: 45px !important;
        width: 100%;
    }

    .ednsm-baslik {
        position: relative;
        top: 10%;
        color: #223d7f;
    }

    .btn-ed{
        width: 108%;
    }

    #topnav .buy-button{
        padding-left: 0;
        margin-left: 0;
    }

    .buy-button {
        margin-right: -4%;
    }

    .btn-detayli{
        margin-left: 2%;
    }

    .sektorler-ptest{
        text-align: center;
        width: 62%;
        margin-left: 19%;
        font-size: 20px;
    }

    .key-feature .content .title{
        font-size: 14px;
    }

    .card{
        border: none;
    }
    
    .pera-list{
        margin-top: 5%;
    }

    .pilk{
        width: 80%;
    }

    .pera-slider1{
        position: relative;
        left: -3%;
        line-height: normal;
        font-size: 40px;
        width: 58%;
        color: #223d7f;
    }

    .pera-h3test{
        color: #223d7f;
        width: 58%;
    }

    .pera-p, .cerez-p{
        font-size: 19px;
    }

    .finans-slider1{
        position: relative;
        left: -3%;
        line-height: normal;
        font-size: 40px;
        width: 77%;
        color: #223d7f;
    }

    .finans-h3test{
        color: #223d7f;
        width: 80%;
        font-size: 35px;
    }

    .owl-carousel{
        margin-top: 0;
    }

    .ekran{
        width: 100%;
    }

    .pera-kmlr{
        color: #223d7f;
    }

    .pera-kmlr-p{
        font-size: 18px;
    }

    .avantajlar{
        font-size: 40px;
        color: #223d7f;
        margin-bottom: -3%;
    }
    .p-kapsamli{
        font-size: 28px;
        color: #223d7f;
        width: 73%;
        margin-left: 14%;
        font-weight: 600;
        margin-top: -1%;
    }

    .modulbtn-ara{
        margin-bottom: 5%;
    }

    .nlr-p{
        text-align: center;
        width: 89%;
        font-size: 18px;
    }

    .title{
        font-size: 16px !important;
    }

    .sektorler-h3test{
        font-size: 40px;
        color: #223d7f;
        font-weight: 600;
    }

    .fiyat-h3test {
        color: #223d7f;
        font-weight: 600;
        font-size: 34px;
    }

    .pera-slider2{
        font-size: 18px;
        line-height: normal;
        max-width: 400px;
        color: #223d7f;
        margin-left: -2%;
    }

    .pera-h3test {
        width: 72%;
        color: #223d7f;
        margin-top: 2%;
        font-size: 35px;
        margin-bottom: -2%;
    }
    .pazaryericard{
        width: 70%;
        margin-left: 15%;
    }

    .peramini-b{
        color: #223d7f;
        width: 110%;
    }

    .section {
        padding: 60px 0;
    }

    .cardheight {
        height: 150px;
        border: none;
        background: none;
    }

    .sss-img{
        width: 134%;
        margin-left: -17%;
    }

    .finans-list{
        margin-top: 5%;
    }

    .iletisim-b{
        color: #223d7f;
    }

    .personel-slider1{
        position: relative;
        left: -2%;
        line-height: normal;
        font-size: 40px;
        width: 68%;
        color: #223d7f;
        margin-top: -12%;
    }

    #topnav .navigation-menu .has-submenu.active .menu-arrow {
        top: 40%;
        right: 4%;
    }

    .ednsm-btn{
        margin-top: 0;
    }

    .eftrimg{
        width: 105%;
    }

    .nedir-row{
        margin-left: 6%;
        margin-top: 5%;
    }

    .dhimgpos{
        width: 90%;
    }

    .peramini-p{
        font-size: 18px;
    }

    .dhimglogo{
        width: 100%;
    }

    .eftrtext{
        font-size: 18px;
    }

    .frontside{
        width: 110%;
    }

    .mainflip{
        margin-left: 3%;
    }

    .donercard-row{
        margin-left: -7%;
    }

    .pricingrow{
        width: 108%;
        margin-left: -4%;
    }

    .responsiveapp {
        width: 110%;
        margin-left: -5%;
        margin-top: 8%;
    }

    .cerezrow{
        margin-top: 8%;
    }

    .cerez-h3test {
        color: #f77e66;
        font-size: 40px;
        margin-top: 7%;
        margin-bottom: 2%;
        text-align: center;
    }

    .pera-p, .cerez-p{
        font-size: 17px;
    }

    .ednsmfatura{
        width: 100%;
        margin-top: 15%;
    }
    .userinvitationimage{
        justify-content: center;
        border: none;
        width: 70%;
        position: relative;
        
    }
    .userinvitationword{
        justify-content: center;
        border: none;
        width: 100%;
        position: relative;
       
    }
    .footerstyle{
        position: relative;
        top: 5%;
    }
}

@media (min-width: 1200px) and (max-width: 1336px) {
    .logo {

        margin-left: 2%;
        letter-spacing: 1px;
    }
    .reg-img{
        position: relative;
        background-color: transparent !important;
        width: 70%;
        height: auto;
        left: -5%;
        top: 12%;
    }
    .reg-bil{
        position: relative;
        left: -40%;
        border: none;
        width: 136%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    .slider-btn {
        margin-left: -3%;
    }

    .depo-slider-btn {
        margin-left: -2%;
    }
    #topnav .navigation-menu>li>a {
        display: block;
        color: #3c4858;
        font-size: 15px;
        background-color: transparent !important;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: "Nunito", sans-serif;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: -15px;
    }

    #topnav .navigation-menu>li {
        float: left;
        display: block;
        position: relative;
        margin-left: -2%
    }

    .btn .btn-icon {
        margin-right: 100px;
        height: 36px;
        width: 36px;
        line-height: 34px;
        padding: 0;
    }

    .btn-login {
        margin-top: 0;
        margin-bottom: 8%;
    }

    .col-md-2 {
        max-width: 100%;
    }

    .ndnblt-icon {
        width: 70px;
        height: 70px;
    }

    .main-slider .slides .bg-slider {
        height: 90vh;
        margin: 0px;
        padding: 0px;
        min-height: 800px;
    }

    .slick-slide {
        background-size: 127% !important;
        background-position: 92% -8%;
        background-repeat: no-repeat;
        margin: 0px;
        padding: 0px;
    }


    .slider-top{
        margin-left: 1%;
    }

    .display-4 {
        font-size: 55px !important;
        text-align: inherit;
        line-height: normal;
    }

    .title-heading .para-desc {
        font-size: 18px !important;
        line-height: normal;
        margin-left: -2.3%;
        color: #223d7f;
    }

    .title-heading {
        margin-left: 1%;
        margin-top: -7%;
    }

    .home-slider1{
        max-width: 64%;
        font-family: "Nunito", sans-serif;
        position: relative;
        line-height: normal;
        color: #223d7f;
    }

    .para-desc {
        max-width: 490px;
    }

    .h3test {
        color: #223d7f;
        font-size: 35px;
    }

    .h3test-nlr {
        color: #223d7f;
        font-weight: 600;
        font-size: 45px;
    }

    .buttontest {
        font-family: "Nunito", sans-serif;
        background-color: #ffff;
        color: #223d7f;
        padding: 6px 12px 6px 12px;
    }

    .buttontest .text {
        font-family: "Nunito", sans-serif;
        width: 40px;
    }

    .imagetest {
        width: 482px;
        height: 473px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .imagetik {
        position: relative;
        left: 20px;
    }

    .back {
        background-image: url("../images/bulut12/onmuhasebearkaplan.png");
        background-repeat: repeat-y;
        display: block;
        width: 100%;
        height: auto;

    }

    .h1test {
        font-family: "Nunito", sans-serif;
        position: relative;
        left: -3%;
        line-height: normal;
        color: #223d7f;
        font-size: 40px;
        width: 106%;
    }

    .link {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-size: 20px;
    }

    .bg {
        font-family: "Nunito", sans-serif;
        background-color: #e4f2f9;

    }

    .baslik {
        font-family: "Nunito", sans-serif;
        margin-top: 3%;
        margin-bottom: 4%;
        color: #223d7f;
    }

    .orange {
        background-color: #f77e66;
        font-family: "Nunito", sans-serif;
    }

    .daha-h3test{
        color: #223d7f;
    }

    .ptest {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: normal;
        color: #596369;
        text-align: center;
    }

    .pveri {
        text-align: center;
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        max-width: 60%;
    }

    .ndnblt-p {
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        line-height: normal;
        color: #3c4858;
        text-align: center;
        max-width: 60%;
        margin-left: 20%;
    }

    .ucretsizp{
        margin-left: 13%;
    }

    .daha-ptest{
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        text-align: center;
        margin-left: 115px;
        max-width: 75%;
    }

    .dahaneler {
        position: relative;
        width: 100%;
        height: auto;
    }

    .dhimg {
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 6%;
    }

    .pslider {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: #223d7f;
    }

    .onmuh {
        text-align: left;
        color: #223d7f;
    }

    .buttontest1 {
        position: relative;
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        padding: 6px 12px 6px 12px;
        margin-top: -3%;

    }

    .buttontest1hiza {
        position: relative;
        right: -5%;
        margin-top: -4.5%;
    }

    .etext {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-weight: 500;
        font-size: 18px;
        line-height: normal;
        text-transform: none;
        text-align: left;
    }

    .bordertable {
        border: 1px solid #223d7f !important;
    }

    .bordertest {
        border: 1px solid #FABFB3 !important;
        min-width: 200px;
        min-height: 200px;
    }

    .gecis {
        font-family: "Nunito", sans-serif;
        font-weight: bold;
        color: #223d7f;
        font-size: 40px;
        line-height: normal;
        text-align: center;
        margin-top: 5%;
        width: 63%;
        margin-left: 19%;
    }

    .cerceve {
        border: 1px solid white;
    }

    h1 {
        font-size: 45px !important;
        width: 100%;
    }

    .ednsm-baslik {
        position: relative;
        left: 10%;
        top: 10%;
    }

    .buy-button {
        margin-right: 2%;
    }

    .btn-detayli{
        margin-left: 2%;
    }

    .key-feature .content .title {
        font-size: 18px;
    }

    .card{
        border: none;
    }

    .pera-col6{
        margin-top: -11%;
    }

    .pera-kmlr{
        color: #223d7f;
    }

    .pera-kmlr-p{
        width: 83%;
        margin-left: 9%;
        font-size: 18px;
    }

    .peramini-b{
        color: #223d7f;
    }

    .finans-list{
        margin-top: -22%;
    }
    
    .avantajlar{
        font-size: 40px;
        color: #223d7f;
        margin-bottom: -3%;
    }
    .p-kapsamli{
        font-size: 28px;
        color: #223d7f;
        width: 79%;
        margin-left: 10%;
        font-weight: 600;
        margin-top: -3%;
    }

    .pera-slider1{
        position: relative;
        left: -3%;
        line-height: normal;
        font-size: 40px;
        width: 48%;
        color: #223d7f;
    }

    .modulbtn-ara{
        margin-bottom: 2%;
    }

    .pera-h3test, .iletisim-b{
        color: #223d7f;
    }

    .finans-slider1{
        position: relative;
        left: -3%;
        line-height: normal;
        font-size: 40px;
        width: 63%;
        color: #223d7f;
    }

    .finans-h3test{
        color: #223d7f;
        width: 104%;
        font-size: 35px;
    }

    .nlr-p{
        text-align: center;
        width: 84%;
    }

    .sektorler-h3test{
        font-size: 40px;
        color: #223d7f;
        font-weight: 600;
    }

    .fiyat-h3test {
        color: #223d7f;
        font-weight: 600;
        font-size: 34px;
    }

    .pera-slider2{
        font-size: 18px;
        line-height: normal;
        max-width: 47%;
        color: #223d7f;
        margin-left: -3%;
    }

    .pera-h3test {
        max-width: 100%;
        color: #223d7f;
        margin-top: 23%;
        font-size: 35px;
        margin-bottom: -2%;
    }

    .section {
        padding: 60px 0;
    }

    .cardheight {
        height: 150px;
        border: none;
        background: none;
    }

    .personel-slider1{
        font-size: 40px;
        width: 59%;
        color: #223d7f;
        margin-left: -2%;
    }

    #topnav .navigation-menu .has-submenu.active .menu-arrow {
        top: 40%;
        right: 4%;
    }

    .ednsm-btn{
        margin-top: -3%;
    }

    .nedir-row{
        margin-left: 10%;
    }

    .eftrtext{
        font-size: 18px;
    }

    .peramini-p{
        font-size: 18px;
    }

    .p-uyeol{
        font-size: 18px;
    }

    .gecis-section{
        margin-top: -8%;
    }

    .my_section {
        padding-top: 7%;
        padding-bottom: 5%;
    }

    .title-heading .heading{
        margin-left: -2px;
    }

    .frontside{
        width: 110%;
    }

    .mainflip{
        margin-left: 3%;
    }

    .donercard-row{
        margin-left: -6%;
    }

    .responsiveapp {
        width: 110%;
        margin-left: -5%;
        margin-bottom: 5%;
        margin-top: 5%;
    }

    .title{
        font-size: 20px !important;
    }

    .sektorler-ptest{
        font-size: 20px;
        text-align: center;
    }

    .cerezrow{
        margin-top: 8%;
    }

    .cerez-h3test {
        color: #f77e66;
        font-size: 45px;
        margin-top: 7%;
        margin-bottom: 2%;
        text-align: center;
    }

    .pera-p, .cerez-p{
        font-size: 18px;
    }

    .pazaryericard{
        width: 100%;
        margin-top: 10%;
    }

    .sss-img{
        width: 140%;
        margin-left: -20%;
    }

}

@media (min-width: 1337px) and (max-width: 1920px) {
    .logo {
        margin-top: 1%;
        margin-left: -5%;
        letter-spacing: 1px;
    }
    .reg-img{
        position: relative;
        background-color: transparent !important;
        width: 70%;
        height: auto;
        left: -15%;
        top: 12%;
    }
    .reg-bil{
        position: relative;
        left: -40%;
        border: none;
        width: 150%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }
    #topnav .navigation-menu>li>a {
        display: block;
        color: #3c4858;
        font-size: 17px;
        background-color: transparent !important;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: "Nunito", sans-serif;
        padding-left: 4px;
        padding-right: 8px;
        padding-top: 10px;
        margin-top: 18px;
        margin-bottom: -15px;
    }

    .display-4 {
        font-size: 65px !important;
        text-align: justify;
        line-height: normal;
        position: relative;
        max-width: 600px;

    }

    .h3test {
        margin-top: 2%;
        color: #223d7f;
        font-family: "Nunito", sans-serif;
        font-size: 50px;
    }

    .gecis {
        font-family: "Nunito", sans-serif;
        font-weight: bold;
        color: #223d7f;
        font-size: 40px;
        line-height: normal;
        text-align: center;
        margin-top: 5%;
        width: 63%;
        margin-left: 19%;
    }

    .slick-slide {
        background-size: 100% !important;
        background-position: 65% 85%;
        background-repeat: no-repeat;
        margin-right: 0px;
        margin-left: 0px;
    }

    .title-heading .para-desc {
        font-size: 20px !important;
        line-height: normal;
        color: #223d7f;
        position: relative;
        max-width: 550px;
        left: -11%;
    }

    .slider-top{
        margin-left: 3%;
    }

    .section {
        padding: 60px 0;
    }

    .pera-h3test {
        color: #223d7f;
        font-size: 35px;
        margin-top: 3%;
    }

    .cerez-h3test {
        color: #f77e66;
        font-size: 45px;
        margin-top: 7%;
        margin-bottom: 2%;
        text-align: center;
    }

    .prs-h3test {
        margin-top: 2%;
        color: #223d7f;
    }

    .pera-slider1{
        position: relative;
        line-height: normal;
        font-size: 60px;
        color: #223d7f;
        margin-left: -12%;
        max-width: 700px;
    }

    .pera-slider2 {
        position: relative;
        margin-left: -12%;
        font-size: 22px;
        margin-top: 2%;
        width: 55%;
        color: #223d7f;
    }

    .home-slider1 {
        margin-bottom: 2%;
        max-width: 694px;
    }

    .slider-btn {
        margin-left: -12%;
    }

    .arsv-slider1 {
        margin-bottom: 20px;
        max-width: 794px;
    }

    .edonusum-blok {
        position: relative;
        left: 15px;
        top: 10px;

    }

    .container-neden {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .main-slider .slides .bg-slider {
        height: 101vh;
        margin: 0px;
        padding: 0px;
        min-height: 750px;
    }

    .onmuh {
        text-align: left;
        color: #223d7f;
    }

    .ondort-btn {
        position: relative;
        left: 10px;
    }

    .bizimle-ile-btn {
        position: relative;
        left: 10px;
    }

    .ucretsizp {
        margin-left: 75px;
    }

    .daha-ptest {
        font-size: 18px;
    }

    .daha-h3test {
        color: #223d7f;
    }

    .etext {
        text-align: left;

    }

    .buttontest1 {
        position: relative;
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        padding: 6px 12px 6px 12px;

    }

    .buttontest1hiza {
        position: relative;
        right: -3%;
        margin-top: -1%;
    }

    .ndnblt12 {
        margin-top: 5%;
        margin-left: 2%;
        margin-bottom: 2%;
    }

    .ndnblt-p {
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        line-height: normal;
        color: #3c4858;
        text-align: center;
        max-width: 72%;
        margin-left: 15%;
        margin-bottom: 5%;
    }

    .pveri {
        text-align: center;
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        max-width: 60%;
    }

    .daha-ptest{
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #596369;
        text-align: center;
        margin-left: 115px;
        max-width: 75%;
    }

    .text-l {
        text-align: center;
        max-width: 86%;
    }

    .h3test-nlr {
        color: #223d7f;
        font-weight: 600;
        font-size: 45px;
    }

    .ndnblt-icon {
        width: 80px;
        height: 80px;
    }

    .eticaretfull {
        background-color: #e4f2f9;
        border: none;
    }

    .border-top {
        border-top: 1px solid #e9ecef !important;
    }

    .border-bottom {
        border-bottom: 1px solid #e9ecef !important;
    }

    .border-left {
        border-left: 1px solid #e9ecef !important;
    }

    .border-right {
        border-right: 1px solid #e9ecef !important;
    }

    .rounded {
        border-radius: 6px !important;
        border: 2px solid white;
    }

    .btn {
        padding: 8px 20px;
        outline: none;
        text-decoration: none;
        font-size: 16px;
        letter-spacing: 0.5px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        font-weight: 600;
        border-radius: 6px;
    }

    .mobil {
        margin-top: 1%;
        width: 58%;
        margin-left: 21%;
    }

    .buttontest {
        font-family: "Nunito", sans-serif;
        background-color: #ffff;
        color: #223d7f;
        padding: 6px 12px 6px 12px;
    }

    .buttontest .text {
        font-family: "Nunito", sans-serif;
        width: 40px;
        margin-left: 0%;
    }

    .imagetest {
        width: 482px;
        height: 473px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .imagetik {
        position: relative;
        left: 20px;
    }

    .back {
        background-image: url("../images/bulut12/onmuhasebearkaplan.png");
        background-repeat: repeat-y;
        display: block;
        width: 100%;
        height: auto;

    }

    .h1test {
        font-family: "Nunito", sans-serif;
        position: relative;
        left: -12%;
        line-height: normal;
        color: #223d7f;
        font-size: 60px;
    }

    .link {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-size: 20px;
    }

    .bg {
        font-family: "Nunito", sans-serif;
        background-color: #e4f2f9;

    }

    .baslik {
        font-family: "Nunito", sans-serif;
        margin-top: 0;
        margin-bottom: 4%;
        color: #223d7f;
        font-size: 18px;
    }

    .orange {
        background-color: #f77e66;
        font-family: "Nunito", sans-serif;
    }

    .ptest {
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        line-height: normal;
        color: #3c4858;
        text-align: center;
        max-width: 1071px;
    }

    .sektorler-ptest {
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        line-height: normal;
        color: #3c4858;
        text-align: center;
        max-width: 600px;
        margin-left: 250px;
    }

    .pslider {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: normal;
        color: #223d7f;
    }

    .etext {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-weight: 500;
        font-size: 18px;
        line-height: normal;
        text-transform: none;
    }

    .bordertable {
        border: 1px solid #223d7f !important;
    }

    .bordertest {
        border: 1px solid #FABFB3 !important;
        min-width: 200px;
        min-height: 200px;
    }

    .dahaneler {
        position: relative;
        width: 100%;
        height: auto;
    }

    .card-stil {
        margin-left: 15px;
    }

    .dhimg {
        position: relative;
        width: 100%;
        height: auto;
    }

    #topnav .buy-button {
        float: right;
        line-height: 68px;
        padding: 3px 0;
        margin-right: -4%;

    }

    #topnav .buy-button .login-btn-primary {
        display: none;
    }

    #topnav .buy-button .login-btn-light {
        display: inline-block;
    }

    #topnav.nav-sticky .buy-button .login-btn-primary {
        display: inline-block;
    }

    #topnav.nav-sticky .buy-button .login-btn-light {
        display: none;
    }

    .col-lg-6 {
        position: relative;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        font-family: "Nunito", sans-serif;
        line-height: 1.5;
        font-weight: 600;
    }

    .onmuha-link {
        color: #3c4858;
        padding-right: 16px;
        padding-left: 16px;
    }

    .btn-footer-nav {
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        color: #3c4858;
    }

    .edonusum-bg {
        font-family: "Nunito", sans-serif;
        background-color: #e4f2f9;
        margin-top: -13px;
        margin-bottom: 100px;
    }

    .eftr-area {
        margin-left: 6%;
    }

    .cardheight {
        height: 150px;
        border: none;
        background: none;
    }

    .depocard {
        border: 1px solid white;
        margin-bottom: 30px;
        border-radius: 10px;
    }

    .card-title {
        font-size: 27px;
        color: #223d7f;
    }

    .iletisim-img {
        width: 100px;
        height: 100px;
    }

    h1 {
        font-size: 50px !important;
    }

    h5{
        font-size: 18px !important;
    }

    .edonusum-btnlr {
        margin-left: 3%;
    }

    .ednsm-btn {
        margin-top: -2%;
    }

    .btn-detayli {
        position: relative;
        left: 10px;
    }

    .slideshow {
        margin: 0 auto;
        overflow: hidden;
        max-width: 500px;
    }

    .slide {
        height: 400px;
        width: 100%;
        border-radius: 40px;
        display: inline-block;
    }

    .slideshowSlider {
        white-space: nowrap;
        transition: ease 1000ms;
    }

    .slideshowDots {
        text-align: center;
    }

    .slideshowDot {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        margin: 15px 7px 0px;
        background-color: #c4c4c4;
    }

    .container {
        width: 100%;
    }

    .ednsm-baslik {
        position: relative;
        top: 10%;
        color: #223d7f;
    }

    .cerceve {
        border: 1px solid white;
    }

    .title-heading {
        line-height: 26px;
    }

    .title-heading .heading {
        font-size: 40px !important;
        letter-spacing: 1px;
        margin-left: -2px;
    }

    .title-heading .para-desc {
        font-size: 17px;
        letter-spacing: 0.5px;
    }

    .btn .btn-icon{
        margin-right: 100px;
    }

    .key-feature .content .title {
        font-size: 18px;
    }

    .pera-kmlr{
        color: #223d7f;
        margin-left: 7%;
    }

    .pera-kmlr-p{
        margin-left: 9%;
        width: 80%;
        font-size: 18px;
    }

    .peramini-b{
        color: #223d7f;
    }

    .avantajlar{
        color: #223d7f;
        font-size: 40px;
        margin-bottom: -3%;
    }

    .p-kapsamli{
        font-size: 25px;
        color: #223d7f;
        width: 70%;
        margin-left: 15%;
        font-weight: 600;
    }

    .finans-slider1{
        position: relative;
        line-height: normal;
        font-size: 60px !important;
        color: #223d7f;
        margin-left: -12%;
        max-width: 75%;
    }

    h2{
        font-size: 41px !important;
    }

    .finans-h3test{
        color: #223d7f;
        width: 104%;
        font-size: 35px;
    }

    .sektorler-h3test{
        font-size: 50px;
        color: #223d7f;
        font-weight: 600;
    }

    .fiyat-h3test {
        color: #223d7f;
        font-weight: 600;
        font-size: 34px;
    }

    .footer-bar{
        margin-top: 0%;
    }

    .finans-list{
        margin-top: -12%;
    }

    .personel-slider1{
        position: relative;
        line-height: normal;
        font-size: 60px;
        color: #223d7f;
        margin-left: -12%;
        max-width: 71%;
    }

    .iletisim-b{
        color: #223d7f;
    }
    .depo-slider-btn{
        margin-left: -11%;
    }

    .nedir-row{
        margin-top: 6%;
    }

    .eftrtext{
        font-size: 20px;
    }

    .peramini-p{
        font-size: 18px;
    }

    .my_section {
        padding-top: 3%;
        padding-bottom: 5%;
    }

    .p-uyeol{
        font-size: 20px;
    }

    .eftr-button{
        margin-top: -6%;
    }

    .pera-p, .cerez-p{
        font-size: 18px;
    }

    .nlr-p{
        font-size: 18px;
        color: #596369;
    }

    h6{
        font-size: 18px !important;
    }

    .about-cardh{
        height: 100px !important;
    }

    .mdi:before{
        font-size: 24px !important;
    }

    .donercard{
        width: 101%;
        margin-left: 0%;
    }

    .mainflip{
        margin-left: 3%;
    }

    .donercard-row{
        margin-left: -4%;
    }

    .pazaryericard{
        width: 120%;
        margin-left: -16%;
        margin-top: 9%;
    }

    .pazaryericardiki{
        width: 120%;
        margin-left: -13%;
        margin-top: 9%;
    }

    .cerezrow{
        margin-top: 8%;
    }

    .eticaretcont{
        max-width: 1295px !important;
    }

    .eticaretcont-iki{
        max-width: 72%;
    }

    .title{
        font-size: 17px !important;
    }

    .eticarettext{
        font-size: 20px;
        color: #223d7f;
    }

    .ednsmfatura{
        margin-left: -20%;
    }

    .pilk{
        font-size: 16px;
    }

}

@media (min-width: 1921px) {
    .logo {
        width: 345;
        margin-left: -10%;
        letter-spacing: 1px;
    }
    .reg-img{
        position: relative;
        background-color: transparent !important;
        width: 95%;
        height: auto;
        left: -40%;
        top: 12%;
    }
    .reg-bil{
        position: relative;
        left: -35%;
        border: none;
        width: 200%;
        height: auto;
        font-family: "Nunito", sans-serif; 
        background-color: transparent !important;
    }

    #topnav .navigation-menu>li>a {
        display: block;
        color: #3c4858;
        font-size: 18px;
        background-color: transparent !important;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: "Nunito", sans-serif;
        padding-left: 4px;
        padding-right: 8px;
        padding-top: 10px;
        margin-top: 18px;
        margin-bottom: -15px;
    }

    #topnav .navigation-menu>li {
        float: left;
        display: block;
        position: relative;
        margin: 0 10px;
    }

    #topnav .navigation-menu {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .display-4 {
        font-size: 65px !important;
        text-align: justify;
        line-height: normal;
        position: relative;
        max-width: 600px;

    }

    .h3test {
        margin-top: 2%;
        color: #223d7f;
        font-family: "Nunito", sans-serif;
        font-size: 50px;
    }

    .sektorler-h3test{
        font-size: 50px;
        color: #223d7f;
        font-weight: 600;
    }

    .gecis {
        font-family: "Nunito", sans-serif;
        font-weight: bold;
        color: #223d7f;
        font-size: 40px;
        line-height: normal;
        text-align: center;
        width: 53%;
        margin-left: 24%;
    }

    .slick-slide {
        background-size: 100% !important;
        background-position: 65% 85%;
        background-repeat: no-repeat;
        margin-right: 0px;
        margin-left: 0px;
    }

    .title-heading .para-desc {
        font-size: 20px !important;
        line-height: normal;
        color: #223d7f;
        position: relative;
        max-width: 550px;
        left: -11%;
    }

    .pera-h3test {
        color: #223d7f;
        font-size: 40px;
        margin-top: 3%;
    }

    .cerez-h3test {
        color: #223d7f;
        font-size: 40px;
        margin-top: 3%;
    }

    .cerezrow{
        margin-top: 8%;
    }

    .finans-h3test {
        color: #223d7f;
        font-size: 40px;
        margin-top: 3%;
        width: 80%;
    }

    .prs-h3test {
        margin-top: 2%;
        color: #223d7f;
    }

    .pera-slider1 {
        margin-bottom: 100px;
        max-width: 740px;
    }

    .pera-slider2 {
        position: relative;
        margin-top: -80px;
        font-size: 23px;
        line-height: normal;
        letter-spacing: .5px;
        width: 550px;
        margin-left: -12%;
        color: #223d7f;
    }

    .personel-slider1{
        position: relative;
        line-height: normal;
        font-size: 60px;
        color: #223d7f;
        margin-left: -12%;
        margin-bottom: 8%;
        max-width: 65%;
    }

    .home-slider1 {
        margin-bottom: 100px;
        max-width: 694px;
    }

    .slider-btn {
        margin-left: -11%;
    }

    .arsv-slider1 {
        margin-bottom: 20px;
        max-width: 794px;
    }

    .edonusum-blok {
        position: relative;
        left: 15px;
        top: 10px;

    }

    .container-neden {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .main-slider .slides .bg-slider {
        height: 105vh;
        margin: 0px;
        padding: 0px;
        min-height: 1000px;
    }

    .onmuh {
        text-align: left;
        max-width: 150%;
        color: #223d7f;
    }

    .eticarettext{
        text-align: left;
        font-size: 20px;
    }

    .ondort-btn {
        position: relative;
        left: 10px;
    }

    .bizimle-ile-btn {
        position: relative;
        left: 10px;
    }

    .ucretsizp {
        margin-left: 75px;
    }

    .daha-ptest {
        font-size: 22px;
    }

    .daha-h3test {
        color: #223d7f;
    }


    .buttontest1 {
        position: relative;
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        padding: 6px 12px 6px 12px;

    }

    .buttontest1hiza {
        position: relative;
        right: -3%;
        margin-top: -1%;
    }

    .ndnblt12 {
        margin-top: 1%;
        margin-bottom: 2%;
    }

    .ndnblt-p {
        width: 72%;
        margin-left: 12%;
        font-size: 22px;
        margin-bottom: 8%;
    }

    .col-nd{
        margin-left: 1%;
    }

    .text-l {
        text-align: center;
        max-width: 87%;
    }

    .ndnblt-icon {
        width: 50%;
        height: 50%;
    }

    .eticaretfull {
        background-color: #e4f2f9;
        border: none;
    }

    .border-top {
        border-top: 1px solid #e9ecef !important;
    }

    .border-bottom {
        border-bottom: 1px solid #e9ecef !important;
    }

    .border-left {
        border-left: 1px solid #e9ecef !important;
    }

    .border-right {
        border-right: 1px solid #e9ecef !important;
    }

    .rounded {
        border-radius: 15px !important;
        border: 2px solid white;
    }

    .btn {
        padding: 8px 20px;
        outline: none;
        text-decoration: none;
        font-size: 16px;
        letter-spacing: 0.5px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        font-weight: 600;
        border-radius: 6px;
    }

    .mobil {
        margin-top: 1%;
        width: 58%;
        margin-left: 21%;
    }

    .buttontest {
        font-family: "Nunito", sans-serif;
        background-color: #ffff;
        color: #223d7f;
        padding: 6px 12px 6px 12px;
    }

    .buttontest .text {
        font-family: "Nunito", sans-serif;
        width: 40px;
    }

    .imagetest {
        width: 582px;
        height: 570px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .imagetik {
        position: relative;
        left: 20px;
    }

    .back {
        background-image: url("../images/bulut12/onmuhasebearkaplan.png");
        background-repeat: repeat-y;
        background-size: cover;
        display: block;
        width: 100%;
        height: auto;

    }

    .h1test {
        font-family: "Nunito", sans-serif;
        position: relative;
        left: -12%;
        line-height: normal;
        color: #223d7f;
        font-size: 75px;
    }

    .link {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-size: 21px;
    }

    .bg {
        font-family: "Nunito", sans-serif;
        background-color: #e4f2f9;

    }

    .baslik {
        font-family: "Nunito", sans-serif;
        margin-top: 0;
        margin-bottom: 4%;
        color: #223d7f;
        font-size: 22px;
    }

    .orange {
        background-color: #f77e66;
        font-family: "Nunito", sans-serif;
    }

    .ptest {
        font-family: "Nunito", sans-serif;
        font-size: 22px;
        line-height: normal;
        color: #3c4858;
        text-align: center;
        max-width: 1071px;
    }

    .sektorler-ptest {
        font-family: "Nunito", sans-serif;
        font-size: 22px;
        line-height: normal;
        color: #3c4858;
        text-align: center;
        max-width: 600px;
        margin-left: 250px;
    }

    .fiyat-h3test {
        color: #223d7f;
        font-weight: 600;
        font-size: 47px;
    }

    .pslider {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: #223d7f;
    }

    .etext {
        font-family: "Nunito", sans-serif;
        color: #223d7f;
        font-weight: 500;
        font-size: 22px;
        line-height: normal;
        text-transform: none;
        text-align: left;
    }

    .bordertable {
        border: 1px solid #223d7f !important;
    }

    .bordertest {
        border: 1px solid #FABFB3 !important;
        min-width: 200px;
        min-height: 200px;
    }

    .dahaneler {
        position: relative;
        width: 100%;
        height: auto;
    }

    .card-stil {
        margin-left: 15px;
    }

    .dhimg {
        position: relative;
        width: 100%;
        height: auto;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
         
    }

    .etcrtcol{
        margin-left: -6%;
    }

    .eticaretcont{
        max-width: 61% !important;
    }

    .eticaretcont-iki{
        max-width: 62% !important;
    }

    #topnav .buy-button {
        float: right;
        line-height: 68px;
        padding: 3px 0;
        margin-right: -18%;

    }

    #topnav .buy-button .login-btn-primary {
        display: none;
    }

    #topnav .buy-button .login-btn-light {
        display: inline-block;
    }

    #topnav.nav-sticky .buy-button .login-btn-primary {
        display: inline-block;
    }

    #topnav.nav-sticky .buy-button .login-btn-light {
        display: none;
    }

    .col-lg-6 {
        position: relative;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        font-family: "Nunito", sans-serif;
        line-height: 1.5;
        font-weight: 600;
    }

    .onmuha-link {
        color: #3c4858;
        padding-right: 16px;
        padding-left: 16px;
    }

    .btn-footer-nav {
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        color: #3c4858;
    }

    .edonusum-bg {
        font-family: "Nunito", sans-serif;
        background-color: #e4f2f9;
        margin-top: -13px;
        margin-bottom: 100px;
    }

    .eftrtext{
        font-size: 21px;
    }

    .eftr-area{
        margin-left: -5%;
    }

    .eftr-button{
        margin-left: 10%;
    }

    .cardheight {
        height: 150px;
        border: none;
        background: none;
    }

    .depocard {
        border: 1px solid white;
        margin-bottom: 30px;
        border-radius: 10px;
    }

    .card-title {
        font-size: 30px;
        color: #223d7f;
    }

    .iletisim-img {
        width: 100px;
        height: 100px;
    }

    h1 {
        font-size: 58px !important;
    }

    h2{
        font-size: 48px !important;
    }

    h3{
        font-size: 45px !important;
    }

    h4{
        font-size: 30px !important;
    }

    h6{
        font-size: 20px !important;
    }

    .edonusum-btnlr {
        margin-left: 3%;
    }

    .ednsm-btn {
        margin-top: -1%;
        margin-bottom: 100px;
    }

    .btn-detayli {
        position: relative;
        left: 10px;
    }

    .slideshow {
        margin: 0 auto;
        overflow: hidden;
        max-width: 500px;
    }

    .slide {
        height: 400px;
        width: 100%;
        border-radius: 40px;
        display: inline-block;
    }

    .slideshowSlider {
        white-space: nowrap;
        transition: ease 1000ms;
    }

    .slideshowDots {
        text-align: center;
    }

    .slideshowDot {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        margin: 15px 7px 0px;
        background-color: #c4c4c4;
    }

    .container {
        width: 100%;
    }

    .ednsm-baslik {
        position: relative;
        left: 10%;
        top: 10%;
    }

    .cerceve {
        border: 1px solid white;
    }

    .title-heading {
        line-height: 26px;
        margin-left: -1%;
    }

    .title-heading .heading {
        font-size: 40px !important;
        letter-spacing: 1px;
        margin-left: 0;
    }

    .title-heading .para-desc {
        font-size: 17px;
        letter-spacing: 0.5px;
        margin-top: -80px;
    }

    .key-feature .content .title {
        font-size: 18px;
    }

    .section {
        padding: 60px 0;
    }
    
    .section .section-title {
        text-align: center;
        color: #007b5e;
        margin-bottom: 50px;
        text-transform: uppercase;
    }

    .modulbtn-ara{
        margin-bottom: 2%;
    }

    .pera-slider1{
        position: relative;
        line-height: normal;
        font-size: 75px;
        color: #223d7f;
        margin-left: -12%;
    }

    .pilk {
        font-size: 17px;
    }

    .finans-slider1{
        position: relative;
        line-height: normal;
        font-size: 60px;
        color: #223d7f;
        margin-left: -12%;
        margin-bottom: 9%;
        max-width: 75%;
    }

    .btn-icon{
        margin-left: -140%;
    }

    .iletisim-b{
        color: #223d7f;
    }

    .footer{
        margin-top: 0;
    }

    .pera-kmlr{
        color: #223d7f;
        margin-left: 7%;
    }

    .pera-kmlr-p{
        margin-left: 8%;
        width: 85%;
        font-size: 20px;
    }

    .peramini-b{
        color: #223d7f;
        width: 129%;
    }

    .eftrimg{
        width: 120%;
        margin-left: -9%;
    }

    .avantajlar{
        color: #223d7f;
        font-size: 55px;
        margin-bottom: -3%;
    }

    .p-kapsamli{
        font-size: 37px;
        color: #223d7f;
        font-weight: 600;
        margin-top: 3%;
    }

    .verimerkezi{
        margin-top: 6%;
    }

    .pveri{
        text-align: center;
        font-size: 22px;
    }

    .responsiveapp{
        width: 150%;
        margin-left: -27%;
        margin-top: 5%;
    }

    .ondort-btn{
        padding: 3%;
        margin-left: -6%;
    }

    .bizimle-ile-btn{
        padding: 3%;
    }

    .h3test-nlr {
        color: #223d7f;
        font-weight: 600;
        font-size: 50px;
    }

    .nlr-p{
        font-size: 22px;
        color: #223d7f;
    }

    .gecis-section{
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .eftr-img{
        width: 130%;
    }

    .iletisim-font, .p-uyeol{
        font-size: 22px;
    }

    .dhimglogo{
        margin-left: -25%;
        width: 115%;
    }

    .peramini-p{
        font-size: 22px;
    }
    

    .avantajlar-blok{
        margin-left: -20%;
    }

    .avantajlar-blok3{
        margin-top: 10%;
    }

    .onikipro{
        padding-left: 30%;
        margin-left: -29%;
    }

    .avantajlar-p{
        font-size: 20px;
    }

    .pera-p, .cerez-p{
        font-size: 20px;
    }

    .iletisim-p{
        font-size: 20px;
    }

    .text-foot, .footfont{
        font-size: 17px !important;
    }

    .foottfont{
        font-size: 20px;
    }

    .btnstil{
        font-size: 20px;
    }

    .avatar{
        width: 50%;
    }

    .uclu-col{
        width: 120%;
        margin-left: -10%
    }

    .my_section {
        padding-top: 8%;
        padding-bottom: 3%;
    }

    .pazaryerirow{
        margin-left: 5%;
        width: 90%;
    }

    .donercard-row{
        margin-left: -16%;
    }

    .mainflip{
        margin-left: 6%;
    }

    .frontside{
        width: 116%;
    }
    
    .pazaryericard{
        width: 105%;
        margin-left: -5%;
        margin-top: 7%;
    }

    .pazaryericardiki{
        width: 141%;
        margin-left: -35%;
        margin-top: 9%;
    }

}

::-moz-selection {
    background: rgba(47, 85, 212, 0.9);
    color: #ffffff;
}

::selection {
    background: rgba(47, 85, 212, 0.9);
    color: #ffffff;
}

a {
    text-decoration: none !important;
}

p {
    line-height: 1.6;
}

.btn-primary {
    background-color: #2f55d4 !important;
    border: 1px solid #2f55d4 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus {
    background-color: #2443ac !important;
    border-color: #2443ac !important;
    color: #ffffff !important;
}

.btn-outline-primary {
    border: 1px solid #2f55d4;
    color: #2f55d4;
    background-color: transparent;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: #2f55d4;
    border-color: #2f55d4;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-secondary {
    background-color: #5a6d90 !important;
    border: 1px solid #5a6d90 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
    box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.focus {
    background-color: #465571 !important;
    border-color: #465571 !important;
    color: #ffffff !important;
}

.btn-outline-secondary {
    border: 1px solid #5a6d90;
    color: #5a6d90;
    background-color: transparent;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
    background-color: #5a6d90;
    border-color: #5a6d90;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
    box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-success {
    background-color: #2eca8b !important;
    border: 1px solid #2eca8b !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
    box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus {
    background-color: #25a06e !important;
    border-color: #25a06e !important;
    color: #ffffff !important;
}

.btn-outline-success {
    border: 1px solid #2eca8b;
    color: #2eca8b;
    background-color: transparent;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.focus,
.btn-outline-success:not(:disabled):not(.disabled):active {
    background-color: #2eca8b;
    border-color: #2eca8b;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
    box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

.btn-warning {
    background-color: #f17425 !important;
    border: 1px solid #f17425 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
    box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus {
    background-color: #d55b0e !important;
    border-color: #d55b0e !important;
    color: #ffffff !important;
}

.btn-outline-warning {
    border: 1px solid #f17425;
    color: #f17425;
    background-color: transparent;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.focus,
.btn-outline-warning:not(:disabled):not(.disabled):active {
    background-color: #f17425;
    border-color: #f17425;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
    box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
}

.btn-info {
    background-color: #17a2b8 !important;
    border: 1px solid #17a2b8 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
    box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus {
    background-color: #117a8b !important;
    border-color: #117a8b !important;
    color: #ffffff !important;
}

.btn-outline-info {
    border: 1px solid #17a2b8;
    color: #17a2b8;
    background-color: transparent;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.focus,
.btn-outline-info:not(:disabled):not(.disabled):active {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
    box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-danger {
    background-color: #e43f52 !important;
    border: 1px solid #e43f52 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
    box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.focus {
    background-color: #d21e32 !important;
    border-color: #d21e32 !important;
    color: #ffffff !important;
}

.btn-outline-danger {
    border: 1px solid #e43f52;
    color: #e43f52;
    background-color: transparent;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.focus,
.btn-outline-danger:not(:disabled):not(.disabled):active {
    background-color: #e43f52;
    border-color: #e43f52;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
    box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
}

.btn-dark {
    background-color: #3c4858 !important;
    border: 1px solid #3c4858 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
    box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.btn-dark.focus {
    background-color: #272f3a !important;
    border-color: #272f3a !important;
    color: #ffffff !important;
}

.btn-outline-dark {
    border: 1px solid #3c4858;
    color: #3c4858;
    background-color: transparent;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.focus,
.btn-outline-dark:not(:disabled):not(.disabled):active {
    background-color: #3c4858;
    border-color: #3c4858;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
    box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-muted {
    background-color: #8492a6 !important;
    border: 1px solid #8492a6 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
    box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-muted:hover,
.btn-muted:focus,
.btn-muted:active,
.btn-muted.active,
.btn-muted.focus {
    background-color: #68788f !important;
    border-color: #68788f !important;
    color: #ffffff !important;
}

.btn-outline-muted {
    border: 1px solid #8492a6;
    color: #8492a6;
    background-color: transparent;
}

.btn-outline-muted:hover,
.btn-outline-muted:focus,
.btn-outline-muted:active,
.btn-outline-muted.active,
.btn-outline-muted.focus,
.btn-outline-muted:not(:disabled):not(.disabled):active {
    background-color: #8492a6;
    border-color: #8492a6;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
    box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-light {
    background-color: #f8f9fc !important;
    border: 1px solid #f8f9fc !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
    box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active,
.btn-light.focus {
    background-color: #d4daed !important;
    border-color: #d4daed !important;
    color: #ffffff !important;
}

.btn-outline-light {
    border: 1px solid #f8f9fc;
    color: #f8f9fc;
    background-color: transparent;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.focus,
.btn-outline-light:not(:disabled):not(.disabled):active {
    background-color: #f8f9fc;
    border-color: #f8f9fc;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
    box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-footer {
    background-color: #202942 !important;
    border: 1px solid #202942 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
    box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

.btn-footer:hover,
.btn-footer:focus,
.btn-footer:active,
.btn-footer.active,
.btn-footer.focus {
    background-color: #0f131f !important;
    border-color: #0f131f !important;
    color: #ffffff !important;
}

.btn-outline-footer {
    border: 1px solid #202942;
    color: #202942;
    background-color: transparent;
}

.btn-outline-footer:hover,
.btn-outline-footer:focus,
.btn-outline-footer:active,
.btn-outline-footer.active,
.btn-outline-footer.focus,
.btn-outline-footer:not(:disabled):not(.disabled):active {
    background-color: #202942;
    border-color: #202942;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
    box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}




.btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

button:not(:disabled) {
    outline: none;
    border: 0;
}

.social-icon li a:hover .fea-social {
    fill: #d8dae3;
}

.shadow {
    -webkit-box-shadow: 0 0 3px rgba(22, 28, 45, 0.15) !important;
    box-shadow: 0 0 3px rgba(22, 28, 45, 0.15) !important;
}

.shadow-large {
    -webkit-box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
    box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}

.badge-primary {
    background-color: #2f55d4 !important;
    color: #ffffff !important;
}

.badge-outline-primary {
    background-color: transparent !important;
    color: #2f55d4 !important;
    border: 1px solid #2f55d4 !important;
}

.badge-secondary {
    background-color: #5a6d90 !important;
    color: #ffffff !important;
}

.badge-outline-secondary {
    background-color: transparent !important;
    color: #5a6d90 !important;
    border: 1px solid #5a6d90 !important;
}

.badge-success {
    background-color: #2eca8b !important;
    color: #ffffff !important;
}

.badge-outline-success {
    background-color: transparent !important;
    color: #2eca8b !important;
    border: 1px solid #2eca8b !important;
}

.badge-warning {
    background-color: #f17425 !important;
    color: #ffffff !important;
}

.badge-outline-warning {
    background-color: transparent !important;
    color: #f17425 !important;
    border: 1px solid #f17425 !important;
}

.badge-info {
    background-color: #17a2b8 !important;
    color: #ffffff !important;
}

.badge-outline-info {
    background-color: transparent !important;
    color: #17a2b8 !important;
    border: 1px solid #17a2b8 !important;
}

.badge-danger {
    background-color: #e43f52 !important;
    color: #ffffff !important;
}

.badge-outline-danger {
    background-color: transparent !important;
    color: #e43f52 !important;
    border: 1px solid #e43f52 !important;
}

.badge-dark {
    background-color: #3c4858 !important;
    color: #ffffff !important;
}

.badge-outline-dark {
    background-color: transparent !important;
    color: #3c4858 !important;
    border: 1px solid #3c4858 !important;
}

.badge-muted {
    background-color: #8492a6 !important;
    color: #ffffff !important;
}

.badge-outline-muted {
    background-color: transparent !important;
    color: #8492a6 !important;
    border: 1px solid #8492a6 !important;
}

.badge-light {
    background-color: #f8f9fc !important;
    color: #ffffff !important;
}

.badge-outline-light {
    background-color: transparent !important;
    color: #f8f9fc !important;
    border: 1px solid #f8f9fc !important;
}

.badge-footer {
    background-color: #202942 !important;
    color: #ffffff !important;
}

.badge-outline-footer {
    background-color: transparent !important;
    color: #202942 !important;
    border: 1px solid #202942 !important;
}

.badge {
    padding: 5px 8px;
    border-radius: 3px;
    letter-spacing: 0.5px;
    font-size: 10px;
}

.badge.badge-light {
    color: #3c4858 !important;
    background-color: #f8f9fc !important;
}

.badge.badge-outline-light {
    color: #3c4858 !important;
    border: 1px solid #b1bcdd !important;
    background-color: transparent !important;
}

.badge.badge-pill {
    border-radius: 20px;
}

.badge:focus,
.badge.focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.fea {
    stroke-width: 1.8;
}

.fea.icon-sm {
    height: 16px;
    width: 16px;
}

.fea.icon-ex-md {
    height: 20px;
    width: 20px;
}

.fea.icon-m-md {
    height: 28px;
    width: 28px;
}

.fea.icon-md {
    height: 35px;
    width: 35px;
}

.fea.icon-lg {
    height: 42px;
    width: 42px;
}

.fea.icon-ex-lg {
    height: 52px;
    width: 52px;
}

.avatar.avatar-sm {
    height: 50px;
    width: 50px;
}

.avatar.avatar-small {
    height: 64px;
    width: 64px;
}

.avatar.avatar-md {
    height: 85px;
    width: 85px;
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, #ffffff, #ffffff);
    z-index: 9999999;
}

#preloader #status {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

#preloader #status .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
}

#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #2f55d4;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

#preloader #status .spinner .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.back-to-top {
    z-index: 99;
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 32px;
    height: 32px;
    line-height: 30px;
    display: none;
    color: #3c4858;
    background-color: #ced4da;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-box-shadow: 0 0 3px rgba(22, 28, 45, 0.15);
    box-shadow: 0 0 3px rgba(22, 28, 45, 0.15);
}

.back-to-top .icons {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    stroke-width: 2;
    height: 14px;
    width: 14px;
}

.back-to-top:hover {
    background-color: #2f55d4;
    color: #ffffff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.back-to-top:hover .icons {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.bg-half-260 {
    padding: 260px 0;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;

}

.bg-half-170 {
    padding: 170px 0;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

.bg-half-100 {
    padding: 100px 0;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

.home-wrapper:before {
    content: "";
    position: absolute;
    bottom: 25rem;
    right: 60rem;
    width: 90rem;
    height: 35rem;
    border-radius: 17rem;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #f8f9fc;
}


.demos-box {
    position: relative;
    z-index: 1;
    transition: all 0.5s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
}


.ribbon.ribbon-primary span {
    background-color: #2f55d4 !important;
}

.ribbon.ribbon-primary span:before {
    border-left-color: #2443ac;
    border-top-color: #2443ac;
}

.ribbon.ribbon-primary span:after {
    border-right-color: #2443ac;
    border-top-color: #2443ac;
}

.ribbon.ribbon-secondary span {
    background-color: #5a6d90 !important;
}

.ribbon.ribbon-secondary span:before {
    border-left-color: #465571;
    border-top-color: #465571;
}

.ribbon.ribbon-secondary span:after {
    border-right-color: #465571;
    border-top-color: #465571;
}

.ribbon.ribbon-success span {
    background-color: #2eca8b !important;
}

.ribbon.ribbon-success span:before {
    border-left-color: #25a06e;
    border-top-color: #25a06e;
}

.ribbon.ribbon-success span:after {
    border-right-color: #25a06e;
    border-top-color: #25a06e;
}

.ribbon.ribbon-warning span {
    background-color: #f17425 !important;
}

.ribbon.ribbon-warning span:before {
    border-left-color: #d55b0e;
    border-top-color: #d55b0e;
}

.ribbon.ribbon-warning span:after {
    border-right-color: #d55b0e;
    border-top-color: #d55b0e;
}

.ribbon.ribbon-info span {
    background-color: #17a2b8 !important;
}

.ribbon.ribbon-info span:before {
    border-left-color: #117a8b;
    border-top-color: #117a8b;
}

.ribbon.ribbon-info span:after {
    border-right-color: #117a8b;
    border-top-color: #117a8b;
}

.ribbon.ribbon-danger span {
    background-color: #e43f52 !important;
}

.ribbon.ribbon-danger span:before {
    border-left-color: #d21e32;
    border-top-color: #d21e32;
}

.ribbon.ribbon-danger span:after {
    border-right-color: #d21e32;
    border-top-color: #d21e32;
}

.ribbon.ribbon-dark span {
    background-color: #3c4858 !important;
}

.ribbon.ribbon-dark span:before {
    border-left-color: #272f3a;
    border-top-color: #272f3a;
}

.ribbon.ribbon-dark span:after {
    border-right-color: #272f3a;
    border-top-color: #272f3a;
}

.ribbon.ribbon-muted span {
    background-color: #8492a6 !important;
}

.ribbon.ribbon-muted span:before {
    border-left-color: #68788f;
    border-top-color: #68788f;
}

.ribbon.ribbon-muted span:after {
    border-right-color: #68788f;
    border-top-color: #68788f;
}

.ribbon.ribbon-light span {
    background-color: #f8f9fc !important;
}

.ribbon.ribbon-light span:before {
    border-left-color: #d4daed;
    border-top-color: #d4daed;
}

.ribbon.ribbon-light span:after {
    border-right-color: #d4daed;
    border-top-color: #d4daed;
}

.ribbon.ribbon-blue span {
    background-color: #2f55d4 !important;
}

.ribbon.ribbon-blue span:before {
    border-left-color: #2443ac;
    border-top-color: #2443ac;
}

.ribbon.ribbon-blue span:after {
    border-right-color: #2443ac;
    border-top-color: #2443ac;
}

.ribbon.ribbon-footer span {
    background-color: #202942 !important;
}

.ribbon.ribbon-footer span:before {
    border-left-color: #0f131f;
    border-top-color: #0f131f;
}

.ribbon.ribbon-footer span:after {
    border-right-color: #0f131f;
    border-top-color: #0f131f;
}

.ribbon {
    position: absolute;
    top: -5px;
    width: 75px;
    height: 75px;
    z-index: 2;
}

.ribbon.ribbon-right {
    right: -5px;
}

.ribbon.ribbon-right span {
    right: -21px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.ribbon.ribbon-left {
    left: -5px;
}

.ribbon.ribbon-left span {
    left: -21px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.ribbon span {
    position: absolute;
    top: 19px;
    width: 100px;
    color: #ffffff;
}

.ribbon span:before,
.ribbon span:after {
    content: "";
    position: absolute;
    top: 100%;
    z-index: -1;
    border: 3px solid transparent;
}

.ribbon span:before {
    left: 0;
}

.ribbon span:after {
    right: 0;
}

.demos-box:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
    box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}

/* Feature */
.key-feature {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}



.key-feature .icon {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 24px;
    background: -webkit-gradient(linear, left top, right top, from(transparent), to(rgba(47, 85, 212, 0.15)));
    background: linear-gradient(90deg, transparent, rgba(47, 85, 212, 0.15));
}

.key-feature:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
}

.mt-100 {
    margin-top: 100px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mover {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

@keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

#topnav {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;
    background-color: white;
    border: 0;
    transition: none;

}

#topnav .logo {
    float: left;
    color: #3c4858 !important;
    margin-top: 1%;
}

#topnav .has-submenu {
    position: relative;

}

#topnav .has-submenu.active a {
    color: #ffffff;
}

#topnav .navbar-toggle {
    border: 0;
    position: relative;
    width: 60px;
    padding: 0;
    margin: 0;
    cursor: pointer;
}



#topnav .navbar-toggle span {
    height: 2px;
    width: 100%;
    background-color: #3c4858;
    display: block;
    margin-bottom: 5px;

}

#topnav .navbar-toggle span:last-child {
    margin-bottom: 0;
}



#topnav .navbar-toggle.open span {
    position: absolute;
}

#topnav .navbar-toggle.open span:first-child {
    top: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#topnav .navbar-toggle.open span:nth-child(2) {
    visibility: hidden;
}

#topnav .navbar-toggle.open span:last-child {
    width: 100%;
    top: 6px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#topnav .navbar-toggle.open span:hover {
    background-color: #2f55d4;
}

#topnav .navbar-toggle:hover,
#topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu>li>a:hover {
    background-color: transparent;
}





#topnav .navigation-menu>li:hover>a,
#topnav .navigation-menu>li:active>a {
    color: #2f55d4 !important;
}



#topnav .navigation-menu>li>a:hover,
#topnav .navigation-menu>li>a:active {
    color: #2f55d4;
}

#topnav .menu-extras {
    float: right;
}

#topnav.scroll {
    background-color: #ffffff;
    border: none;
    -webkit-box-shadow: 0 0 3px rgba(22, 28, 45, 0.15);
    box-shadow: 0 0 3px rgba(22, 28, 45, 0.15);
}

#topnav.scroll .navigation-menu>li>a {
    color: #3c4858;
}

#topnav.scroll .navigation-menu>li:hover>a,
#topnav.scroll .navigation-menu>li.active>a {
    color: #2f55d4;
}

#topnav.defaultscroll.dark-menubar .logo {
    line-height: 70px;
}

#topnav.defaultscroll.scroll .logo {
    line-height: 62px;
}

#topnav.defaultscroll.scroll.dark-menubar .logo {
    line-height: 62px;
}

#topnav.nav-sticky {
    background: #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(22, 28, 45, 0.15);
    box-shadow: 0 0 3px rgba(22, 28, 45, 0.15);
}



#topnav.nav-sticky .navigation-menu.nav-light>li>a {
    color: #3c4858;
}


.twentytwenty-before-label,
.twentytwenty-after-label {
    display: none !important;
}

.twentytwenty-overlay:hover {
    background: rgba(60, 72, 88, 0.05);
}

.twentytwenty-handle {
    background: #2f55d4;
    border-color: #f1f3f9 !important;
}

.social-icon li a {
    color: #3c4858;
    border: 1px solid #3c4858;
    display: inline-block;
    height: 32px;
    width: 32px;
    line-height: 30px;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.footer-icon li a {
    color: white;
    border: 1px solid #3c4858;
    display: inline-block;
    height: max-content;
    width: max-content;
    line-height: 30px;
    text-align: center;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
    border-radius: 5%;
}

.social-icon li a:hover {
    background-color: #adb5bd !important;
    background: #adb5bd !important;
    border-color: #adb5bd !important;
    color: #ffffff !important;
}

.social-icon.social li a {
    color: #adb5bd;
    border-color: #adb5bd;
}

.social-icon a {
    color: #3c4858;
    border: 1px solid #3c4858;
    display: inline-block;
    height: 32px;
    width: 32px;
    line-height: 30px;
    text-align: center;
    overflow: hidden;
    position: relative;
}

#topnav .navbar-toggle .lines {
    margin-right: 0 !important;
}


.footer {
    background: #202942;
    position: relative;
    color: #adb5bd;
    
}

.footer.footer-bar {
    padding: 15px 0;
    border-top: 1px solid #3d4349;
}





#team {
    background: #eee !important;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
}



#team .card {
    border: none;
    background: #ffffff;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    min-height: 312px;
}

.backside .card a {
    font-size: 18px;
    color: #007b5e !important;
}

.frontside .card .card-title,
.backside .card .card-title {
    color: #007b5e !important;
}

.frontside .card .card-body img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.show {
    display: block !important;
}

.hidden {
    display: none !important;
    visibility: hidden !important;
}

.invisible {
    visibility: hidden;
}

.texttable {
    font-family: "Nunito", sans-serif;
    line-height: normal;
    font-weight: 600;
    font-size: 18px;
    color: #ffff;
    text-align: center;
}

.text-strike {
    text-decoration: line-through;
}

.basliktable {
    font-family: "Nunito", sans-serif;
    line-height: normal;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    padding: 0px;
    margin: 0px;
    color: #223d7f;
}

.basliktablekdv {
    font-family: "Nunito", sans-serif;
    line-height: normal;
    text-align: center;
    font-weight: 300;
    font-size: 10px;
    padding: 0px;
    margin: 0px;
}

.basliktableucret {
    font-family: "Nunito", sans-serif;
    line-height: normal;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    padding: 0px;
    margin: 0px;
    color: #f77e66;
}

.btnmail {
    font-family: "Nunito", sans-serif;
    color: #f77e66;
    line-height: normal;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    position: center;
    background-color: #223d7f;

}

.footeradress {
    font-family: "Nunito", sans-serif;
    line-height: normal;
    text-align: left;
    color: white;
    font-size: 12px;
}

.erp_banner_area_two .cloud_animation li:nth-child(4) {
    top: 400px;
    right: 77px;
    animation: jurk 4s 2s linear infinite alternate;
}

.erp_banner_area_two .cloud_animation li:nth-child(2) {
    top: 197px;
    left: 863px;
    animation: jurk 4s 2s linear infinite alternate;
}



#sorular {
    padding-top: 120px;
    padding-right: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.explore-btn>a {
    color: #4babab;
}

.explore-btn>a:hover {
    color: #f77e66;
    font-size: 20px;
}

.sertifikafont {
    font-size: 12px;
    position: relative;
    right: 15%;
    margin-top: 10%;
}

.colpadding {
    position: relative;
    margin-right: -2%;
}

.container-right {
    margin-left: 8%;
}

.percardimg {
    position: relative;
    left: -20px;
    width: 40px;
}

.perdiv {
    position: relative;
    left: 35px;
    top: -40px;
}

.perabutt {
    background: #f77e66;
}

.perabutt:hover {
    background: #f1593b;
}

.iletisim-btn {
    background: #f77e66;
}

.iletisim-btn:hover {
    background: #f1593b;
}

.iletisimbutt {
    background: #f77e66;
}

.iletisimbutt:hover {
    background: #f1593b;
}

.depocardd {
    border: none;
    background: none;
}

.depocard img{
    margin-bottom: 15px;
}

.depocard:hover {
    border: 1px solid #a5c524;
}

.btn-onmuha {
    border: 1px solid #f9e8c0;
    background: #f9e8c0;
}

.btn-onmuha:hover {
    background-color: #fdd882;
    outline: 1px solid #fdd882;
}



.btn-eticaret {
    border: 1px solid #caefdd;
    background: #caefdd;
}

.btn-eticaret:hover {
    background-color: #94f3c5;
    outline: 1px solid #94f3c5;
}

.eticaret-link {
    color: #3c4858;
    padding-right: 38px;
    padding-left: 38px;
}

.btn-finance {
    border: 1px solid #e8ebed;
    background: #e8ebed;
}

.btn-finance:hover {
    background-color: #c3c7ca;
    outline: 1px solid #c3c7ca;
}

.finance-link {
    color: #3c4858;
    padding-right: 8px;
    padding-left: 8px;
}

.btn-donusum {
    border: 1px solid #f0f2b1;
    background: #f0f2b1;
}

.btn-donusum:hover {
    background-color: #dde275;
    outline: 1px solid #dde275;
}

.donusum-link {
    color: #3c4858;
    padding-right: 25px;
    padding-left: 25px;
}

.btn-personel {
    border: 1px solid #e6dff7;
    background: #e6dff7;
}

.btn-personel:hover {
    background-color: #c2a9fd;
    outline: 1px solid #c2a9fd;
}

.personel-link {
    color: #3c4858;
}

.btn-burdanbi {
    border: 1px solid #ffe1e3;
    background: #ffe1e3;
}

.btn-burdanbi:hover {
    background-color: #f8959c;
    outline: 1px solid #f8959c;
}

.burdanbi-link {
    color: #3c4858;
    padding-right: 38px;
    padding-left: 38px;
}

.btn-perakende {
    border: 1px solid #d2f4f7;
    background: #d2f4f7;
}

.btn-perakende:hover {
    background-color: #99f5fd;
    outline: 1px solid #99f5fd;
}

.perakende-link {
    color: #3c4858;
    padding-right: 32px;
    padding-left: 32px;
}

.btn-posguru {
    border: 1px solid #cbe3f7;
    background: #cbe3f7;
}

.btn-posguru:hover {
    background-color: #88caff;
    outline: 1px solid #88caff;
}

.posguru-link {
    color: #3c4858;
    padding-right: 40px;
    padding-left: 40px;
}

.bg-button{
    background-color: #007bff;
}

.mor-btn:hover {
   background: #7952B3 !important;
   border: none;
}

.uyeolbuton:hover {
    background: #ffe1e3 !important;
    color: none !important;
}